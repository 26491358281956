// eslint-disable-next-line no-unused-vars
import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'core/store';
import { Button, Grid, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import paths from 'core/routing/paths';
import useIsTeamRolesDepartmentMember from 'core/hooks/useIsTeamRolesDepartmentMember';
import { UserTeamRoleTeamDepartment, UserType } from 'pages/UserManagement/UserManagement.models';
import { useIsUserType } from 'core/hooks/useIsUserType';
import { ICustomerListItem } from 'pages/Customers/Customers.models';
import { customerStatus } from 'pages/Customers/CustomersCreate/constants';

interface ICustomersCreateFormButtonsProps {
  isEditMode: boolean
}

const CustomersCreateFormButtons: FC<ICustomersCreateFormButtonsProps> = ({ isEditMode }) => {
  const {
    submitForm,
    isValid,
    setFieldValue,
    values,
  } = useFormikContext<ICustomerListItem>();
  const isLoading = useAppSelector((state) => state.customers.createCustomer.isLoading);
  const isUserAdmin = useIsUserType(UserType.ADMIN);
  const isUserSeller = useIsTeamRolesDepartmentMember(UserTeamRoleTeamDepartment.SELLER);
  const isStatusSwitchAllowed = isUserAdmin || isUserSeller;

  const navigate = useNavigate();

  const onToggleStatus = () => {
    const updatedStatus = values.status === customerStatus.active
      ? customerStatus.inactive
      : customerStatus.active;
    setFieldValue('status', updatedStatus);
  };

  const onCancel = () => {
    navigate(paths.customersList);
  };

  return (
    <Grid
      sx={{ mt: 2 }}
      container
      direction="row"
      alignItems="center"
    >
      <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
        <Button
          sx={{ mr: 2 }}
          onClick={submitForm}
          disabled={isLoading || !isValid}
          variant="contained"
          type="submit"
        >
          {isEditMode ? 'Save' : 'Create'}
        </Button>
        <Button
          onClick={onCancel}
          disabled={isLoading}
          variant="outlined"
          type="button"
        >
          Cancel
        </Button>
      </Box>
      {isStatusSwitchAllowed && (
      <Button
        sx={{ ml: 'auto', minWidth: '135px' }}
        onClick={onToggleStatus}
        variant="outlined"
        type="button"
        disabled={isLoading}
        startIcon={values.status ? <CancelIcon /> : <CheckCircleIcon />}
      >
        {values.status ? 'Deactivate' : 'Activate'}
      </Button>
      )}
    </Grid>
  );
};

export default CustomersCreateFormButtons;
