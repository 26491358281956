import React from 'react';
import EscalationSupportChartsProvider from '@dashboardEscalationSupport/EscalationSupportCharts/EscalationSupportChartsContext';
import SellerFiltersByPeriod from '@dashboardEscalationSupport/EscalationSupportCharts/EscalationSupportFiltersByPeriod';
import EscalationSupportChartsTotal from '@dashboardEscalationSupport/EscalationSupportCharts/Charts/Total/EscalationSupportChartsTotal';
import EscalationSupportEscalationTypeChart
  from '@dashboardEscalationSupport/EscalationSupportCharts/Charts/EscalationType/EscalationSupportEscalationTypeChart';

import './EscalationSupportCharts.scss';

const EscalationSupportCharts = () => (
  <EscalationSupportChartsProvider>
    <SellerFiltersByPeriod />
    <div className="escalation-support__charts">
      <EscalationSupportChartsTotal />
      <EscalationSupportEscalationTypeChart />
    </div>
  </EscalationSupportChartsProvider>
);

export default EscalationSupportCharts;
