import {
  getEngagementsTotalOrdersRequest,
  getEngagementsTotalOrdersSuccess,
  getEngagementsTotalOrdersFailure,
} from './actionsCreators';
import getEngagementsTotalOrders from './api.service';

const getEngagementsTotalOrdersAction = (controller, params) => async (dispatch) => {
  try {
    const { signal } = controller;
    dispatch(getEngagementsTotalOrdersRequest());
    const { data } = await getEngagementsTotalOrders(signal, params);
    dispatch(getEngagementsTotalOrdersSuccess(data));
  } catch (error) {
    dispatch(getEngagementsTotalOrdersFailure(error));

    throw error;
  }
};

export default getEngagementsTotalOrdersAction;
