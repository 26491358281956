import React, { useEffect, useState } from 'react';
import FiltersByPeriod from 'pages/Dashboard/shared/FiltersByPeriod';
import ProjectAcceptanceChart from '@dashboardWirelinePMDepartment/ProjectAcceptanceChart';
import { HTTPService } from 'core/services';
import { useAppDispatch } from 'core/store';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import getProjectAcceptanceAction from '@dashboardWirelinePMDepartment/GetProjectAcceptance/actions';
import { defaultPeriod, notifierMessage } from '@dashboardWirelinePMDepartment/constants';
import getProjectStatusAction from '@dashboardWirelinePMDepartment/GetProjectStatus/actions';
import ProjectStatusChart from '@dashboardWirelinePMDepartment/ProjectStatusChart';
import './ProjectManagerDepartment.scss';

// TODO uncomment GetGeneralEngagementRequest and GeneralEngagementChart when BE will be ready

let projectAcceptanceController = HTTPService.getController();
let projectStatusController = HTTPService.getController();
// let generalEngagementController = HTTPService.getController();

const ProjectManagerDepartment = () => {
  const { showErrorNotifier } = useNotifiers();

  const dispatch = useAppDispatch();

  const [period, setPeriod] = useState(defaultPeriod);

  const cancelGetProjectAcceptanceRequest = () => {
    HTTPService.cancelRequest(projectAcceptanceController);
  };

  const cancelGetProjectStatusRequest = () => {
    HTTPService.cancelRequest(projectStatusController);
  };

  // const cancelGetGeneralEngagementRequest = () => {
  //   HTTPService.cancelRequest(generalEngagementController);
  // };

  const getProjectAcceptance = async (newPeriod) => {
    cancelGetProjectAcceptanceRequest();

    try {
      projectAcceptanceController = HTTPService.getController();
      await dispatch(getProjectAcceptanceAction(projectAcceptanceController, newPeriod));
    } catch (e) {
      showErrorNotifier(notifierMessage.projectAcceptance.error, e);
    }
  };
  const getProjectStatus = async (newPeriod) => {
    cancelGetProjectStatusRequest();

    try {
      projectStatusController = HTTPService.getController();
      await dispatch(getProjectStatusAction(projectStatusController, newPeriod));
    } catch (e) {
      showErrorNotifier(notifierMessage.projectStatus.error, e);
    }
  };

  // const getGeneralEngagement = async (newPeriod) => {
  //   cancelGetGeneralEngagementRequest();
  //
  //   try {
  //     generalEngagementController = HTTPService.getController();
  //     await dispatch(getGeneralEngagementAction(generalEngagementController, newPeriod));
  //   } catch (e) {
  //    showNotifierMessage(e, notifierMessage.generalEngagement.error);
  //   }
  // };

  const updateCharts = (newPeriod) => {
    getProjectAcceptance(newPeriod);
    getProjectStatus(newPeriod);
    // getGeneralEngagement(newPeriod);

    setPeriod(newPeriod);
  };

  const cancelRequests = () => {
    cancelGetProjectAcceptanceRequest();
    cancelGetProjectStatusRequest();
    // cancelGetGeneralEngagementRequest();
  };

  useEffect(() => {
    updateCharts(period);

    return () => cancelRequests();
  }, []);

  return (
    <div className="project-manager">
      <div className="project-manager__filters">
        <FiltersByPeriod
          period={period}
          onChange={updateCharts}
        />
      </div>
      <div className="project-manager__pie-charts">
        <ProjectAcceptanceChart />
        <ProjectStatusChart />
        {/* <GeneralEngagementChart /> */}
      </div>
    </div>
  );
};

export default ProjectManagerDepartment;
