import {
  GET_RENEWAL_SOW_REQUEST,
  GET_RENEWAL_SOW_SUCCESS,
  GET_RENEWAL_SOW_FAILURE,
} from 'core/actions/Sales/GetSalesRenewalSow/actionsTypes';

export const getSalesRenewalSowRequest = () => ({ type: GET_RENEWAL_SOW_REQUEST });

export const getSalesRenewalSowSuccess = (data) => ({
  type: GET_RENEWAL_SOW_SUCCESS,
  data,
});

export const getSalesRenewalSowFailure = (error) => ({
  type: GET_RENEWAL_SOW_FAILURE,
  error,
});
