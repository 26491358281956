import React from 'react';
import {
  Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText,
} from '@mui/material';

import PropTypes from 'prop-types';
import './ConfirmDialog.scss';

const ConfirmDialog = ({
  title, text, visible, onCancel, onConfirm, showTitle, cancelLabel, confirmLabel,
  showCancelButton,
}) => (
  <Dialog
    open={visible}
    onClose={onCancel}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    { showTitle && (
      <DialogTitle id="alert-dialog-title" className="confirm-dialog__title">
        { title }
      </DialogTitle>
    )}
    <DialogContent className="confirm-dialog__content">
      <DialogContentText id="alert-dialog-description">
        {text}
      </DialogContentText>
    </DialogContent>
    <DialogActions className="confirm-dialog__actions" sx={{ p: 2 }}>
      {showCancelButton && (
        <Button variant="outlined" onClick={onCancel}>
          {cancelLabel}
        </Button>
      )}
      <Button
        onClick={onConfirm}
        variant="contained"
        type="submit"
      >
        {confirmLabel}
      </Button>
    </DialogActions>
  </Dialog>
);

ConfirmDialog.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  showTitle: PropTypes.bool,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  showCancelButton: PropTypes.bool,
};

ConfirmDialog.defaultProps = {
  visible: false,
  title: 'Are you sure you want to leave page?',
  text: 'Data you have entered would not be saved.',
  showTitle: true,
  cancelLabel: 'No',
  confirmLabel: 'Yes',
  showCancelButton: true,
};

export default ConfirmDialog;
