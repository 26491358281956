import { combineReducers } from '@reduxjs/toolkit';

import { getSalesSowReducer } from 'core/actions/Sales/GetSalesSow';
import { salesSowItemReducer } from 'core/actions/Sales/SalesSowItem';
import { getSalesSowMetaReducer } from 'core/actions/Sales/GetSalesSowMeta';
import { requestOrdersReducer } from 'core/actions/Sales/RequestOrders';
import { getProductCategoriesReducer } from 'core/actions/Sales/GetProductCategories';
import { salesSowOrderFormReducer } from 'core/actions/Sales/SalesSowOrderForm/GetSalesSowOrderForm';
import getEngagementsLisReducer from 'pages/WirelineEngagements/WirelineEngagementsMainPage/WirelineEngagementsList/GetEngagementsList/reducer';
import commentsReducer from 'pages/WirelineEngagements/WirelineEngagementsComments/reducer';
import attachmentsReducer from 'pages/WirelineEngagements/WirelineEngagementsAttachments/reducer';
import { getContractBundlesReducer } from '@EngagementsSowData/GetContractBundles';
import { getActiveNumbersReducer } from 'core/actions/Sales/GetSalesActiveNumbers';
import { salesActiveNumbersItemReducer } from 'core/actions/Sales/SalesActiveNumbersItem';
import getRenewalSowReducer from 'core/actions/Sales/GetSalesRenewalSow/reducer';
import { salesRenewalSowItemReducer } from 'core/actions/Sales/SalesRenewalSowItem';
import { salesOrderCommentsReducer } from 'core/actions/Sales/OrderComments';
import { getTSDsReducer } from 'core/actions/Sales/GetTSDs';
import { getCarriersReducer } from 'core/actions/Sales/GetCarriers';
import { createProductsReducer } from 'core/actions/Sales/CreateProduct';
import { salesActiveNumbersMetaReducer } from 'core/actions/Sales/GetSalesActiveNumbersMeta';
import { getScopeDescriptionReducer } from 'pages/WirelineEngagements/WirelineEngagementsCreate/EngagementFormsWrapper/ScopeDescription/actions/GetScopeDescription';
import { createScopeDescriptionReducer } from 'pages/WirelineEngagements/WirelineEngagementsCreate/EngagementFormsWrapper/ScopeDescription/actions/CreateScopeDescription';
import { editScopeDescriptionReducer } from 'pages/WirelineEngagements/WirelineEngagementsCreate/EngagementFormsWrapper/ScopeDescription/actions/EditScopeDescription';

const wirelineEngagementsReducer = combineReducers({
  list: getEngagementsLisReducer,
  salesSow: getSalesSowReducer,
  salesSowItem: salesSowItemReducer,
  salesSowOrderForm: salesSowOrderFormReducer,
  requestOrders: requestOrdersReducer,
  salesSowMeta: getSalesSowMetaReducer,
  comments: commentsReducer,
  orderComments: salesOrderCommentsReducer,
  attachments: attachmentsReducer,
  contractBundles: getContractBundlesReducer,
  activeNumbers: getActiveNumbersReducer,
  salesActiveNumbersItem: salesActiveNumbersItemReducer,
  renewalSow: getRenewalSowReducer,
  renewalSowItem: salesRenewalSowItemReducer,
  productCategories: getProductCategoriesReducer,
  TSDs: getTSDsReducer,
  carriers: getCarriersReducer,
  createProducts: createProductsReducer,
  salesActiveNumbersMeta: salesActiveNumbersMetaReducer,
  scopeDescription: getScopeDescriptionReducer,
  editScopeDescription: editScopeDescriptionReducer,
  createScopeDescription: createScopeDescriptionReducer,
});

export default wirelineEngagementsReducer;
