import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { useAppDispatch, useAppSelector } from 'core/store';
import { clearAttachmentsAction } from 'core/actions/crm/InsideSales/InsideSalesAttachments';
import {
  postInsideSalesItemDraftAction,
  postInsideSalesItemUpdateDraftAction,
} from 'core/actions/crm/InsideSales/PostInsideSalesDraft/PostInsideSalesDraft';
import { HTTPService } from 'core/services';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useTableContext } from 'shared/Table/TableContext';
import InsideSalesCreateFormSchema from 'pages/InsideSales/InsideSalesList/InsideSalesListTable/InsideSalesListTableModals/InsideSalesCreateModalForm/InsideSalesCreateFormSchema';
import InsideSalesCreateModal from 'pages/InsideSales/InsideSalesList/InsideSalesListTable/InsideSalesListTableModals/InsideSalesCreateModalForm/InsideSalesCreateModal';
import { createRequestDraftMessages, createRequestMessages } from 'pages/InsideSales/InsideSalesList/constants';
// eslint-disable-next-line no-unused-vars
import { IInsideSalesDraftItem } from 'pages/InsideSales/model';

import './InsideSalesCreateModalForm.scss';

interface IInsideSalesCreateModalFormProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

let draftAbortController = HTTPService.getController();

const initialValues = InsideSalesCreateFormSchema.getDefault();

const InsideSalesCreateModalForm: React.FC<
  IInsideSalesCreateModalFormProps
> = ({ isOpen, setIsOpen }) => {
  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();
  const { getTableData } = useTableContext();

  const draftId = useAppSelector((state) => state.insideSales.draft.id);

  const dispatch = useAppDispatch();

  const onSubmit = (params: IInsideSalesDraftItem, { resetForm, setErrors }) => {
    if (!draftId) return;

    dispatch(postInsideSalesItemUpdateDraftAction({ id: draftId, params }))
      .unwrap()
      .then(() => {
        getTableData();
        setIsOpen(false);
        resetForm();
        dispatch(clearAttachmentsAction());

        showSuccessNotifier(createRequestMessages.success);
      })
      .catch((e) => {
        setErrors(e?.response?.data);
        showErrorNotifier(createRequestMessages.error, e);
      });
  };

  useEffect(() => {
    if (!isOpen) return;

    draftAbortController = HTTPService.getController();

    dispatch(postInsideSalesItemDraftAction({ controller: draftAbortController }))
      .unwrap()
      .catch((e) => {
        showErrorNotifier(createRequestDraftMessages.error, e);
      });

    return () => HTTPService.cancelRequest(draftAbortController);
  }, [isOpen]);

  return (
    <Formik
      initialValues={initialValues as IInsideSalesDraftItem}
      validationSchema={InsideSalesCreateFormSchema}
      onSubmit={onSubmit}
      validateOnBlur
      validateOnChange
    >
      <InsideSalesCreateModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </Formik>
  );
};

export default InsideSalesCreateModalForm;
