import useIsUserTeamRoleName from 'core/hooks/useIsUserTeamRoleName';
import { UserTeamRoleName, UserTeamRoleTeamDepartment } from 'pages/UserManagement/UserManagement.models';
import useIsTeamRolesDepartmentMember from 'core/hooks/useIsTeamRolesDepartmentMember';

export const useUserPermission = () => {
  const isIndividualSupported = useIsUserTeamRoleName(UserTeamRoleName.INDIVIDUAL_SUPPORTER);
  const isInsideSales = useIsTeamRolesDepartmentMember(UserTeamRoleTeamDepartment.INSIDE_SALES);

  const isByUsersComponentUnavailable = isInsideSales && isIndividualSupported;

  return {
    isByUsersComponentUnavailable,
  };
};
