import {
  getEngagementsOrdersByPeriodRequest,
  getEngagementsOrdersByPeriodSuccess,
  getEngagementsOrdersByPeriodFailure,
} from './actionsCreators';
import getEngagementsOrdersByPeriod from './api.service';

const getEngagementsOrdersByPeriodAction = (controller, params) => async (dispatch) => {
  try {
    const { signal } = controller;
    dispatch(getEngagementsOrdersByPeriodRequest());
    const { data } = await getEngagementsOrdersByPeriod(signal, params);
    dispatch(getEngagementsOrdersByPeriodSuccess(data));
  } catch (error) {
    dispatch(getEngagementsOrdersByPeriodFailure(error));
    throw error;
  }
};

export default getEngagementsOrdersByPeriodAction;
