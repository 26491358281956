import React from 'react';
import {
  DashboardOutlined, InsertChartOutlinedTwoTone, TableChartOutlined,
  AttachMoneyOutlined, MessageOutlined, PeopleOutlined, PersonAddAltOutlined,
} from '@mui/icons-material';
import paths from 'core/routing/paths';
import accessNames from 'core/routing/accessRoutesNames';

const sidebarConfig = [
  {
    id: 'dashboard',
    text: 'Dashboard',
    icon: <DashboardOutlined />,
    path: paths.dashboard,
    accessName: accessNames.dashboard,
  },
  {
    id: 'wireline-engagements',
    text: 'Wireline Engagements',
    icon: <InsertChartOutlinedTwoTone />,
    path: paths.wirelineEngagements,
    accessName: accessNames.wirelineEngagements,
  },
  {
    id: 'mobility-engagements',
    text: 'Mobility Engagements',
    icon: <TableChartOutlined />,
    path: paths.mobilityEngagements,
    accessName: accessNames.mobilityEngagements,
  },
  {
    id: 'inside-sales',
    text: 'Inside Sales Requests',
    icon: <AttachMoneyOutlined />,
    path: paths.insideSales,
    accessName: accessNames.insideSalesRequests,
  },
  {
    id: 'mobility-requests',
    text: 'Mobility Request',
    icon: <TableChartOutlined />,
    path: paths.mobilityRequests,
    accessName: accessNames.mobilityRequests,
  },
  {
    id: 'customer-support-requests',
    text: ' Customers Support Request',
    icon: <MessageOutlined />,
    path: paths.customerSupportRequests,
    accessName: accessNames.customerSupportRequests,
  },

  {
    id: 'customers',
    text: 'Customers',
    icon: <PeopleOutlined />,
    path: paths.customers,
    accessName: accessNames.customers,
  },
  {
    id: 'user-management',
    text: 'User Management',
    icon: <PersonAddAltOutlined />,
    path: paths.userManagement,
    accessName: accessNames.userManagement,
  },
  // {
  //   text: 'RPA',
  //   icon: <SnippetFolderOutlined />,
  //   path: '/admin/rpa/',
  //   accessName: accessNames.rpaTool,
  //   target: '_blank',
  // },
];

export default sidebarConfig;
