import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { createEngagementDraft, getEngagementById } from 'core/api/wirelineEngagements.api';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { IEngagement } from 'pages/WirelineEngagements/WirelineEngagements.models';

const useEngagementsForm = () => {
  const { wirelineEngagementId } = useParams();
  const { showErrorNotifier } = useNotifiers();

  const [engagementsInitialValues, setEngagementsInitialValues] = useState<
    IEngagement | null
  >(null);
  const [draftId, setDraftId] = useState<number | null>(null);
  const [isInitializing, setIsInitializing] = useState(true);
  const [isEditModeGeneral, setIsEditModeGeneral] = useState(false);

  const getEngagement = async (engagementId) => {
    try {
      const { data } = await getEngagementById(engagementId);
      setEngagementsInitialValues(data);
    } catch (e) {
      showErrorNotifier('Error while loading engagement', e);
    } finally {
      setIsInitializing(false);
    }
  };

  const createDraft = async () => {
    try {
      const { data } = await createEngagementDraft();
      setDraftId(data.id);
    } catch (error) {
      showErrorNotifier('Error while creating draft', error);
    } finally {
      setIsInitializing(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (wirelineEngagementId) {
        await getEngagement(wirelineEngagementId);
      } else {
        await createDraft();
      }
    })();
  }, []);

  const initialCustomerId = engagementsInitialValues?.customer?.id;

  return {
    initialCustomerId,
    draftId,
    engagementsInitialValues,
    setEngagementsInitialValues,
    isInitializing,
    isEditModeGeneral,
    setIsEditModeGeneral,
  };
};

export default useEngagementsForm;
