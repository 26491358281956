export const listPath = '/list';
const createPath = '/create';
const editPath = '/edit';

const engagement = 'engagement';
const customer = 'customer';
const user = 'user';

const pathIdDynamic = '/:id';

const dashboardPath = '/dashboard';
const wirelinePath = '/wireline';
const escalationSupportPath = '/escalation-support';
const salesPath = '/sales';
const projectManagerPath = '/project-manager';
const contractSpecialistPath = '/contract-specialist';
const billingPath = '/billing';
const retentionManager = '/retention-manager';

const userManagementPath = '/user-management';

const customersPath = '/customers';

const customersIdDynamic = '/:customerId';
const wirelineEngagementsIdDynamic = '/:wirelineEngagementId';
const requestOrderIdDynamic = '/:requestOrderId';

const contractBundleIdDynamic = '/:contractBundleId';

const wirelineEngagementsPath = '/wireline-engagements';
const insideSalesRequestPath = '/inside-sales-request';
const insideSalesPath = '/inside-sales';

const sowDataPath = '/sow-data';

export const contractFormPath = '/contract-form';

export const requestOrderPath = '/request-order';

const activeNumbersPath = '/active-numbers';

const renewalSowPath = '/renewal-sow';

const dashboardPaths = {
  dashboard: dashboardPath,
  dashboardWirelinePath: `${dashboardPath}${wirelinePath}`,
  dashboardWirelineSalesPath: `${dashboardPath}${wirelinePath}${salesPath}`,
  dashboardWirelineProjectManagerPath: `${dashboardPath}${wirelinePath}${projectManagerPath}`,
  dashboardWirelineContractSpecialistPath: `${dashboardPath}${wirelinePath}${contractSpecialistPath}`,
  dashboardWirelineBillingPath: `${dashboardPath}${wirelinePath}${billingPath}`,
  dashboardWirelineInsideSalesPath: `${dashboardPath}${wirelinePath}${insideSalesPath}`,
  dashboardRetentionManagerPath: `${dashboardPath}${wirelinePath}${retentionManager}`,
  dashboardEscalationSupportPath: `${dashboardPath}${escalationSupportPath}`,
};

const insideSalesRequestPaths = {
  insideSales: insideSalesRequestPath,
};

const customerPaths = {
  customers: customersPath,
  customersList: `${customersPath}${listPath}`,
  customersCreate: `${customersPath}${createPath}-${customer}`,
  customersEdit: `${customersPath}${editPath}-${customer}${customersIdDynamic}`,
};

const userManagementPaths = {
  userManagement: userManagementPath,
  userManagementList: `${userManagementPath}${listPath}`,
  userManagementCreate: `${userManagementPath}${createPath}-${user}`,
  userManagementEdit: `${userManagementPath}${editPath}-${user}`,
  userManagementEditWithId: `${userManagementPath}${editPath}-${user}${pathIdDynamic}`,
};

const wirelineEngagementsPaths = {
  wirelineEngagements: wirelineEngagementsPath,
  wirelineEngagementsList: `${wirelineEngagementsPath}${listPath}`,
  wirelineEngagementsCreate: `${wirelineEngagementsPath}${createPath}-${engagement}`,
  wirelineEngagementsEdit: `${wirelineEngagementsPath}${editPath}-${engagement}`,
  wirelineEngagementsEditWithId: `${wirelineEngagementsPath}${editPath}-${engagement}${wirelineEngagementsIdDynamic}`,
  wirelineEngagementsSowData: `${wirelineEngagementsPath}${editPath}-${engagement}${wirelineEngagementsIdDynamic}${sowDataPath}${customersIdDynamic}`,
  wirelineEngagementsContractForm: `${wirelineEngagementsPath}${editPath}-${engagement}${wirelineEngagementsIdDynamic}${sowDataPath}${customersIdDynamic}${contractFormPath}`,
  wirelineEngagementsContractFormView: `${wirelineEngagementsPath}${editPath}-${engagement}${wirelineEngagementsIdDynamic}${sowDataPath}${customersIdDynamic}${contractFormPath}${contractBundleIdDynamic}`,
  wirelineEngagementsRequestOrder: `${wirelineEngagementsPath}${editPath}-${engagement}${wirelineEngagementsIdDynamic}${sowDataPath}${customersIdDynamic}${requestOrderPath}`,
  wirelineEngagementsRequestOrderView: `${wirelineEngagementsPath}${editPath}-${engagement}${wirelineEngagementsIdDynamic}${sowDataPath}${customersIdDynamic}${requestOrderPath}${requestOrderIdDynamic}`,
  wirelineEngagementsActiveNumbers: `${wirelineEngagementsPath}${editPath}-${engagement}${wirelineEngagementsIdDynamic}${activeNumbersPath}${customersIdDynamic}`,
  wirelineEngagementsRenewalSow: `${wirelineEngagementsPath}${editPath}-${engagement}${wirelineEngagementsIdDynamic}${renewalSowPath}${customersIdDynamic}`,
};

const paths = {
  mobilityEngagements: '/mobility-engagements',
  mobilityRequests: '/mobility-requests',
  customerSupportRequests: '/customer-support-requests',

  ...dashboardPaths,
  ...wirelineEngagementsPaths,
  ...insideSalesRequestPaths,
  ...customerPaths,
  ...userManagementPaths,
};

export const getUserEditPath = (id) => `${userManagementPath}${editPath}-${user}/${id}`;

export const getCustomersEditPath = (id) => `${customersPath}${editPath}-${customer}/${id}`;
export const getEngagementsEditPath = (id) => `${wirelineEngagementsPath}${editPath}-${engagement}/${id}`;

export const getSowDataPath = (engagementsId, customerId) => `${getEngagementsEditPath(engagementsId)}${sowDataPath}/${customerId}`;

export const getActiveNumbersPath = (engagementsId, customerId) => `${getEngagementsEditPath(engagementsId)}${activeNumbersPath}/${customerId}`;

export const getRenewalSowPath = (engagementsId, customerId) => `${getEngagementsEditPath(engagementsId)}${renewalSowPath}/${customerId}`;

export default paths;
