import useIsUserTeamRoleName from 'core/hooks/useIsUserTeamRoleName';
import { UserTeamRoleName, UserTeamRoleTeamDepartment, UserType } from 'pages/UserManagement/UserManagement.models';
import useIsTeamRolesDepartmentMember from 'core/hooks/useIsTeamRolesDepartmentMember';

import { useIsUserType } from 'core/hooks/useIsUserType';

const { ADMIN } = UserType;
const { DEPARTMENT_HEAD } = UserTeamRoleName;
const { PROJECT_MANAGER, SELLER } = UserTeamRoleTeamDepartment;

export const useCustomersPermissions = () => {
  const isAdmin = useIsUserType(ADMIN);
  const isSeller = useIsTeamRolesDepartmentMember(SELLER);
  const isProjectManager = useIsTeamRolesDepartmentMember(PROJECT_MANAGER);

  const isDepartamentHead = useIsUserTeamRoleName(DEPARTMENT_HEAD);

  const isProjectManagerDepartamentHead = isProjectManager && isDepartamentHead;

  const isAvailableEditCustomer = isAdmin || isSeller || isProjectManagerDepartamentHead;
  const isAvailableEditCustomerSeller = isAdmin || isProjectManagerDepartamentHead;

  const isAvailableSomeRowAction = isAvailableEditCustomer || isAvailableEditCustomerSeller;
  const isAvailableAllRowActions = isAvailableEditCustomer && isAvailableEditCustomerSeller;

  return {
    isAvailableSomeRowAction,
    isAvailableEditCustomer,
    isAvailableAllRowActions,
    isAvailableEditCustomerSeller,
  };
};
