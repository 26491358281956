import React from 'react';
import { useBookingPermission } from '@dashboardWirelineSellerDepartment/BookingInfo/useBookingPermission';
import BookedOrderButton from '@dashboardWirelineSellerDepartment/BookingInfo/BookingTableTopToolbar/BookingActionButton/BookedOrder/BookedOrderButton';

const BookingActionButton = () => {
  const { isActionsAvailable } = useBookingPermission();

  if (!isActionsAvailable) return null;

  return <BookedOrderButton />;
};

export default BookingActionButton;
