import useIsUserTeamRoleName from 'core/hooks/useIsUserTeamRoleName';
import { UserTeamRoleName, UserTeamRoleTeamDepartment, UserType } from 'pages/UserManagement/UserManagement.models';
import useIsTeamRolesDepartmentMember from 'core/hooks/useIsTeamRolesDepartmentMember';

import { useIsUserType } from 'core/hooks/useIsUserType';

export const useUserPermission = () => {
  const isTechnicalOverlay = useIsUserTeamRoleName(UserTeamRoleName.TECHNICAL_OVERLAY);
  const isSeller = useIsTeamRolesDepartmentMember(UserTeamRoleTeamDepartment.SELLER);
  const isAdmin = useIsUserType(UserType.ADMIN);

  const isSellerTechnicalOverlay = isTechnicalOverlay && isSeller;

  const isTechnicalOverlayAvailable = isSellerTechnicalOverlay || isAdmin;

  return {
    isTechnicalOverlayAvailable,
  };
};
