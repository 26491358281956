import { getRenewalSow } from 'core/api/sales.api';
import {
  getSalesRenewalSowRequest,
  getSalesRenewalSowSuccess,
  getSalesRenewalSowFailure,
} from 'core/actions/Sales/GetSalesRenewalSow/actionsCreators';

const getSalesRenewalSowAction = (
  controller: AbortController,
  params: {
    page: number;
    page_size: number;
    search: string;
    ordering?: string | undefined;
  },
  customerId: string | number| undefined,
) => async (dispatch) => {
  try {
    const { signal } = controller;

    dispatch(getSalesRenewalSowRequest());

    const combinedParams = {
      ...params,
      customerId,
    };

    const { data } = await getRenewalSow(signal, combinedParams);

    dispatch(getSalesRenewalSowSuccess(data));
  } catch (error) {
    dispatch(getSalesRenewalSowFailure(error));

    throw error;
  }
};

export default getSalesRenewalSowAction;
