import React from 'react';
import { useField } from 'formik';
import {
  Grid, Tooltip, IconButton, Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import FormikSelectField from 'shared/FormikField/FormikSelectField';
import { IFormRequestTypeProductItem } from 'pages/WirelineEngagements/shared/AddOrderModalForm//product.model';
import AddOrderRequestTypeField from 'pages/WirelineEngagements/shared/AddOrderModalForm/AddOrderRequestTypeForm/AddOrderRequestTypeField';
import {
  fields, sellerRequestTypeATTOptions, sellerRequestTypeNonATTOptions, schemaSellerRequestType,
} from 'pages/WirelineEngagements/shared/AddOrderModalForm/constants';

interface IAddOrderRequestTypeItem {
  attTSDId: number
  itemCount: number
  canRemove: boolean
  onRemove: () => void
  data: IFormRequestTypeProductItem
}

const AddOrderRequestTypeItem: React.FC<IAddOrderRequestTypeItem> = ({
  data, attTSDId, itemCount, onRemove, canRemove,
}) => {
  const [sellerRequestTypeField] = useField(`products[${itemCount}][${fields.sellerRequestType}]`);
  const [paramsField,, paramsHelpers] = useField(`products[${itemCount}].params`);

  const { locationName, productName, tsdId } = data;

  const sellerRequestTypeOptions = tsdId === attTSDId
    ? sellerRequestTypeATTOptions
    : sellerRequestTypeNonATTOptions;

  const isVisibleRelatedField = sellerRequestTypeField.value
    && schemaSellerRequestType[sellerRequestTypeField.value];

  const onChangeHandler = () => {
    const updatedData = Object.fromEntries(Object.entries(paramsField.value).map(([key]) => ([key, ''])));
    paramsHelpers.setValue(updatedData);
    paramsHelpers.setTouched(false);
  };

  return (
    <Grid
      item
      xs={12}
      container
      columnSpacing={3}
    >
      <Grid
        item
        xs={2}
      >
        <Tooltip
          arrow
          placement="top"
          title={locationName}
        >
          <Typography
            noWrap
            variant="body1"
          >
            {locationName}
          </Typography>
        </Tooltip>
      </Grid>
      <Grid
        item
        xs={2}
      >
        <Tooltip
          arrow
          placement="top"
          title={productName}
        >
          <Typography
            noWrap
            variant="body1"
          >
            {productName}
          </Typography>
        </Tooltip>
      </Grid>
      <Grid
        item
        xs={7}
        container
        columnSpacing={2}
      >
        <Grid
          item
          xs={4}
        >
          <FormikSelectField
            required
            fullWidth
            onChange={onChangeHandler}
            options={sellerRequestTypeOptions}
            fieldName={`products[${itemCount}][${fields.sellerRequestType}]`}
          />
        </Grid>
        { isVisibleRelatedField && (
          <AddOrderRequestTypeField
            itemCount={itemCount}
            schema={schemaSellerRequestType[sellerRequestTypeField.value]}
          />
        )}
      </Grid>
      {canRemove
      && (
      <Grid
        item
        xs={1}
        container
        alignItems="flex-start"
        justifyContent="flex-end"
      >
        <IconButton
          onClick={onRemove}
        >
          <DeleteIcon
            fontSize="medium"
          />
        </IconButton>
      </Grid>
      )}
    </Grid>
  );
};

export default AddOrderRequestTypeItem;
