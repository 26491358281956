import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAvailableDashboardSubRoutes } from 'core/routing/useAvailableRoutes';
import TabsRouting from 'shared/Tabs/TabsRouting/TabsRouting';
import { dashboardRoutes } from 'pages/Dashboard/routes';

const BusinessDirections = () => {
  const availableDashboardRoutes = useAvailableDashboardSubRoutes(dashboardRoutes);

  return (
    <>
      <TabsRouting routes={availableDashboardRoutes} />
      <Outlet />
    </>
  );
};

export default BusinessDirections;
