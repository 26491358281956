import React from 'react';
import { IChartItem } from 'core/models/Recharts.models';
import VerticalBarChartContent
  from 'pages/Dashboard/shared/VerticalBarChart/VerticalBarChartContent';

interface IVerticalBarChart {
  data: IChartItem[]
  title: string
  isLoading?: boolean
}

const VerticalBarChart: React.FC<IVerticalBarChart> = ({ data, title, isLoading }) => (
  <div className="engagements-revenue-chart">
    <h3 className="dashboard-bar-chart__title">
      {title}
    </h3>
    <VerticalBarChartContent
      data={data}
      isLoading={isLoading as boolean}
    />
  </div>
);

VerticalBarChart.defaultProps = {
  isLoading: false,
};

export default VerticalBarChart;
