import React from 'react';
import { MRT_ColumnDef as ColumnDef } from 'material-react-table';
import { useParams } from 'react-router-dom';
import getSalesRenewalSowAction from 'core/actions/Sales/GetSalesRenewalSow/actions';
import { TRow } from 'shared/Table/Table.models';
import ContentShrink from 'shared/ContentShrink';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { TableProvider } from 'shared/Table/TableContext';
import { tableIds } from 'shared/Table/constants';
import BackToEditEngagementButton from 'pages/WirelineEngagements/shared/BackToEditEngagementButton';
import { columns, renewalSowDataTitle } from '@EngagementsRenewalSowData/constants';
import RenewalSowDataTable from '@EngagementsRenewalSowData/RenewalSowDataTable';
import { IRenewalSowDataItem } from '@EngagementsRenewalSowData/renewalSowData.models';
import { getWidthToReduce } from 'pages/WirelineEngagements/shared/utilites';
import 'pages/WirelineEngagements/WirelineEngagementsTables/WirelineEngagementsTables.scss';
import './RenewalSowData.scss';

const RenewalSowDataContainer = () => {
  const { customerId } = useParams();
  const {
    activeRowMode,
    secondarySidebarMode,
  } = useTableActiveRowContext<IRenewalSowDataItem>();

  const { isEditMode, isCommentMode } = getTableRowModeBoolean(activeRowMode);

  return (
    <div className="renewal-sow">
      <BackToEditEngagementButton title={renewalSowDataTitle} />
      <ContentShrink
        isShrinked={isEditMode || isCommentMode}
        widthToReduce={getWidthToReduce(!!secondarySidebarMode)}
      >
        <TableProvider<string | number>
          id={tableIds.renewalSow}
          columns={columns as ColumnDef<TRow>[]}
          getDataAction={getSalesRenewalSowAction}
          requestParams={customerId}
          enableRowSelection
          enableEditing
        >
          <RenewalSowDataTable />
        </TableProvider>
      </ContentShrink>
    </div>

  );
};

export default RenewalSowDataContainer;
