import {
  getUserProfileRequest,
  getUserProfileSuccess,
  getUserProfileFailure,
} from './actionsCreators';
import getUserProfile from './api.service';

const getUserProfileAction = (controller) => async (dispatch) => {
  try {
    const { signal } = controller;

    dispatch(getUserProfileRequest());

    const { data } = await getUserProfile(signal);

    dispatch(getUserProfileSuccess(data));

    return data;
  } catch (error) {
    dispatch(getUserProfileFailure(error));
    throw error;
  }
};

export default getUserProfileAction;
