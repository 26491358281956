import {
  getEngagementsOrdersMrcRequest,
  getEngagementsOrdersMrcSuccess,
  getEngagementsOrdersMrcFailure,
} from './actionsCreators';
import getEngagementsOrdersMrc from './api.service';

const getEngagementsOrdersMrcAction = (controller, params) => async (dispatch) => {
  try {
    const { signal } = controller;
    dispatch(getEngagementsOrdersMrcRequest());
    const { data } = await getEngagementsOrdersMrc(signal, params);
    dispatch(getEngagementsOrdersMrcSuccess(data));
  } catch (error) {
    dispatch(getEngagementsOrdersMrcFailure(error));
    throw error;
  }
};

export default getEngagementsOrdersMrcAction;
