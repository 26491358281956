import React from 'react';
import Loader from 'shared/Loader';
import { Box } from '@mui/material';
import EngagementsForm from '@EngagementsForm/EngagementsForm';
import ScopeDescription from 'pages/WirelineEngagements/WirelineEngagementsCreate/EngagementFormsWrapper/ScopeDescription';
import { useWirelineEngagementsCreateContext } from 'pages/WirelineEngagements/WirelineEngagementsCreate/WirelineEngagementsCreateContext';

import './EngagementFormsWrapper.scss';

const EngagementFormsWrapper = () => {
  const { isInitializing } = useWirelineEngagementsCreateContext();

  if (isInitializing) {
    return <Loader />;
  }

  return (
    <Box className="engagements-forms__wrapper">
      <EngagementsForm />
      <ScopeDescription />
    </Box>
  );
};

export default EngagementFormsWrapper;
