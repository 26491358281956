export const profileFields = [
  {
    fieldName: 'signerProfile',
    title: 'Customer Contract Signer',
  },
  {
    fieldName: 'primaryItProfile',
    title: 'Primary IT Contact',
  },
  {
    fieldName: 'alternativeContactProfile',
    title: 'Alternative Contact',
  },
];

export const locationFields = [
  {
    fieldName: 'contractLocation',
    title: 'Contract Location',
  },
  {
    fieldName: 'serviceAddress',
    title: 'Service Address',
  },
  {
    fieldName: 'billingAddress',
    title: 'Billing Address',
  },
  {
    fieldName: 'cpeShipTo',
    title: 'CPE Ship To Address',
  },
];

export const sendRequestOrderNotifierMessage = {
  success: 'Request Order has been sent successfully',
  error: 'Failed to send Request Order',
};

export const sendALLRequestOrderNotifierMessage = {
  success: 'All request Orders has been sent successfully',
  error: 'Failed to send all Request Orders',
};

export const saveRequestOrderNotifierMessage = {
  success: 'Request Order has been saved successfully',
  error: 'Failed to save Request Order',
};

export const loadRequestOrderDataNotifierMessage = {
  error: 'Failed to load Request Order data',
};
