import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getSowDataPath } from 'core/routing/paths';
import AddOrderModalForm from 'pages/WirelineEngagements/shared/AddOrderModalForm';
import { useWirelineEngagementsCreateContext } from 'pages/WirelineEngagements/WirelineEngagementsCreate/WirelineEngagementsCreateContext';

const AddOrderModalFormContextWrap = () => {
  const navigate = useNavigate();
  const {
    initialCustomerId,
    isAddProductModalOpen,
    setIsAddProductModalOpen,
  } = useWirelineEngagementsCreateContext();
  const { wirelineEngagementId } = useParams();

  const onSuccessCreateOrder = () => {
    navigate(getSowDataPath(wirelineEngagementId, initialCustomerId));
  };

  if (!initialCustomerId) {
    return null;
  }

  return (
    <AddOrderModalForm
      customerId={Number(initialCustomerId)}
      isAddProductModalOpen={isAddProductModalOpen}
      setIsAddProductModalOpen={setIsAddProductModalOpen}
      onSubmitSuccessCallback={onSuccessCreateOrder}
    />
  );
};

export default AddOrderModalFormContextWrap;
