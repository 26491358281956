/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import { getSalesSowById, patchSalesSowById } from 'core/api/sales.api';

export interface IState {
  result: object;
  isLoading: boolean;
}

const initialState: IState = {
  result: {},
  isLoading: false,
};

export const getSalesSowItemAction = createAsyncThunk(
  'getSalesSowItemAction',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const { data } = await getSalesSowById(id);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  });

export const patchSalesSowItemAction = createAsyncThunk(
  'patchSalesSowItemAction',
  async ({ id, params }: { id: string | number, params: object }, { rejectWithValue }) => {
    try {
      const { data } = await patchSalesSowById(id, params);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  });

const salesSowItemSlice = createSlice({
  name: 'salesSowItem',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        getSalesSowItemAction.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.result = action.payload;
        })
      .addCase(
        getSalesSowItemAction.rejected,
        (state) => {
          state.isLoading = false;
          state.result = {};
        })
      .addMatcher(
        isAnyOf(patchSalesSowItemAction.rejected, patchSalesSowItemAction.fulfilled),
        (state) => {
          state.isLoading = false;
        })
      .addMatcher(
        isAnyOf(getSalesSowItemAction.pending, patchSalesSowItemAction.pending),
        (state) => {
          state.isLoading = true;
        },
      );
  },
});

export const salesSowItemReducer = salesSowItemSlice.reducer;
