import {
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAILURE,
} from './actionTypes';

export const getUserProfileRequest = () => (
  { type: GET_USER_PROFILE_REQUEST });
export const getUserProfileSuccess = (data) => (
  { type: GET_USER_PROFILE_SUCCESS, data });
export const getUserProfileFailure = (error) => (
  { type: GET_USER_PROFILE_FAILURE, error });
