import React from 'react';
import { Button, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useEngagementsFormPermission } from '@EngagementsForm/useEngagementsFormPermission';
import { useWirelineEngagementsCreateContext } from 'pages/WirelineEngagements/WirelineEngagementsCreate/WirelineEngagementsCreateContext';

const AddProductButtonBlock = () => {
  const { wirelineEngagementId } = useParams();
  const {
    setIsAddProductModalOpen, isEditModeGeneral,
  } = useWirelineEngagementsCreateContext();

  const { isUnavailableEngagementActions } = useEngagementsFormPermission();

  const isCreateMode = !wirelineEngagementId;
  const isAddProductButtonDisabled = isCreateMode
  || isEditModeGeneral
  || isUnavailableEngagementActions;

  const onOpenAddProductModal = () => {
    setIsAddProductModalOpen(true);
  };

  return (
    <Grid
      item
      container
      direction="row"
      className="engagement-forms__add-order"
    >
      <Button
        variant="contained"
        onClick={onOpenAddProductModal}
        disabled={isAddProductButtonDisabled}
      >
        <AddCircleOutlineIcon sx={{ marginRight: '10px' }} />
        <span>
          Add order
        </span>
      </Button>
    </Grid>
  );
};

export default AddProductButtonBlock;
