import React, {
  createContext, useContext, useMemo, useState,
} from 'react';
import useEngagementsForm from '@EngagementsForm/useEngagementsForm';
import { IEngagement } from 'pages/WirelineEngagements/WirelineEngagements.models';

interface IWirelineEngagementsCreateContext {
  isCreateCustomerModalOpen: boolean
  setIsCreateCustomerModalOpen: (isCreateCustomerModalOpen: boolean) => void
  isAddProductModalOpen: boolean
  setIsAddProductModalOpen: (isAddProductModalOpen: boolean) => void
  initialCustomerId: number | undefined
  draftId: number | null
  engagementsInitialValues: IEngagement | null
  setEngagementsInitialValues: (initialData: IEngagement | null) => void
  isInitializing: boolean
  isEditModeGeneral: boolean
  setIsEditModeGeneral: (isInitializing: boolean) => void
  newCompanyName: string
  setNewCompanyName: (companyName: string) => void
}

interface IWirelineEngagementsCreateProviderProps {
  children: JSX.Element[];
}

const WirelineEngagementsCreateContext = createContext<
  IWirelineEngagementsCreateContext
>({} as IWirelineEngagementsCreateContext);

export const WirelineEngagementsCreateProvider: React.FC<
  IWirelineEngagementsCreateProviderProps
> = ({ children }) => {
  const [isCreateCustomerModalOpen, setIsCreateCustomerModalOpen] = useState(false);
  const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);

  const [newCompanyName, setNewCompanyName] = useState('');

  const {
    initialCustomerId,
    draftId,
    isInitializing,
    isEditModeGeneral,
    engagementsInitialValues,
    setEngagementsInitialValues,
    setIsEditModeGeneral,
  } = useEngagementsForm();

  const value = useMemo(() => ({
    isCreateCustomerModalOpen,
    setIsCreateCustomerModalOpen,
    isAddProductModalOpen,
    setIsAddProductModalOpen,
    initialCustomerId,
    draftId,
    engagementsInitialValues,
    setEngagementsInitialValues,
    isInitializing,
    isEditModeGeneral,
    setIsEditModeGeneral,
    newCompanyName,
    setNewCompanyName,
  }), [
    isCreateCustomerModalOpen,
    isAddProductModalOpen,
    draftId,
    engagementsInitialValues,
    isInitializing,
    isEditModeGeneral,
    newCompanyName,
  ]);

  return (
    <WirelineEngagementsCreateContext.Provider value={value}>
      {children}
    </WirelineEngagementsCreateContext.Provider>
  );
};

export const useWirelineEngagementsCreateContext = () => (
  useContext(WirelineEngagementsCreateContext)
);

export default WirelineEngagementsCreateProvider;
