import { combineReducers } from '@reduxjs/toolkit';

import getStatesReducer from 'layouts/BaseLayout/GetStates/reducer';
import getUserProfileReducer from 'layouts/BaseLayout/GetUserProfile/reducer';
import getAppVariablesReducer from 'core/state/GetAppVariables/reducer';
import NotifierReducer from 'shared/Notifier/reducer';
import customersReducer from 'pages/Customers/reducer';
import userManagementReducer from 'pages/UserManagement/reducer';
import wirelineEngagementsReducer from 'pages/WirelineEngagements/reducer';
import dashboardReducer from 'pages/Dashboard/reducer';
import insideSalesReducer from 'pages/InsideSales/reducer';
import { openapiSchemasReducer } from 'core/actions/Openapi/GetOpenapiSchema';
import { getProductsReducer } from 'core/actions/Sales/GetProducts';

const rootReducer = combineReducers({
  appVariables: getAppVariablesReducer,
  states: getStatesReducer,
  userProfile: getUserProfileReducer,
  notifier: NotifierReducer,
  customers: customersReducer,
  users: userManagementReducer,
  wirelineEngagements: wirelineEngagementsReducer,
  dashboard: dashboardReducer,
  insideSales: insideSalesReducer,
  openapiSchemas: openapiSchemasReducer,
  products: getProductsReducer,
});

export default rootReducer;
