import React from 'react';
import {
  Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis,
} from 'recharts';
import { useMediaQuery, useTheme } from '@mui/material';
import { chartNoData, cursorColor, others } from 'pages/Dashboard/shared/constants';
import { IChartItem } from 'core/models/Recharts.models';
import { decimalFormattedNumber } from 'pages/Dashboard/shared/utilities';
import styles from 'assets/styles/_resources.scss';
import ChartTooltip from 'pages/Dashboard/shared/ChartTooltip';
import VerticalBarChartSkeleton
  from 'pages/Dashboard/shared/VerticalBarChart/VerticalBarChartSkeleton';

interface IVerticalBarChartContentProps {
  data: IChartItem[]
  isLoading: boolean
}

const getCellFill = (entry) => (entry.name === others ? `${styles.darkColor100}` : `${styles.secondaryColor}`);

const VerticalBarChartContent: React.FC<
  IVerticalBarChartContentProps
> = ({ isLoading, data }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const barChartMargin = isMobile
    ? {
      top: -12, right: 30, left: 15, bottom: -10,
    }
    : {
      top: -12, right: 30, left: 30, bottom: 20,
    };

  if (isLoading) {
    return (
      <VerticalBarChartSkeleton />
    );
  }

  if (data.length) {
    return (
      <ResponsiveContainer width="99%" height={320}>
        <BarChart
          data={data}
          layout="vertical"
          margin={barChartMargin}
        >
          <CartesianGrid horizontal={false} strokeDasharray="3 3" stroke={styles.lightColor200} />
          <XAxis
            tick={{ fill: styles.darkColor500, fontSize: '11px' }}
            orientation="top"
            stroke={styles.lightColor100}
            type="number"
            dataKey="value"
          />
          <YAxis
            tick={{ fill: styles.darkColor500, fontSize: '11px' }}
            stroke={styles.lightColor100}
            dataKey="name"
            type="category"
            width={150}
          />
          <Tooltip
            cursor={{ fill: cursorColor }}
            content={<ChartTooltip />}
          />
          <Bar
            dataKey="value"
            maxBarSize={12}
            label={{ position: 'right', formatter: decimalFormattedNumber }}
            fill={styles.secondaryColor}
          >
            {data.map((entry) => (
              <Cell
                name={entry.name}
                fill={getCellFill(entry)}
                key={entry.name}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  }

  return chartNoData;
};

export default VerticalBarChartContent;
