import React, { useEffect } from 'react';
import { Paper } from '@mui/material';
import Attachments from 'shared/widgets/Attachments';
import { HTTPService } from 'core/services';
import { useAppDispatch, useAppSelector } from 'core/store';
// eslint-disable-next-line no-unused-vars
import { IDraftAttachment } from 'shared/widgets/Attachments/Attachments.model';
import {
  getInsideSalesAttachmentListAction,
  createInsideSalesAttachmentAction,
  editInsideSalesAttachmentItemAction,
  clearAttachmentsErrorsAction,
  deleteInsideSalesAttachmentItemAction,
  clearAttachmentsAction,
} from 'core/actions/crm/InsideSales/InsideSalesAttachments';
import { FileCategory } from 'pages/InsideSales/model';
import { getInsideSalesAttachmentsConfig } from 'pages/InsideSales/InsideSalesList/InsideSalesListTable/InsideSalesListTableModals/InsideSalesEditRowSidebarForm/InsideSalesEditAttachments/utilites';

let attachmentsController = HTTPService.getController();
const cancelGetInsideSalesAttachmentList = () => HTTPService.cancelRequest(attachmentsController);

interface IInsideSalesEditAttachmentsProps {
  editRowId: number | string | undefined | null
  isViewMode?: boolean
}

const InsideSalesEditAttachments: React.FC<
  IInsideSalesEditAttachmentsProps
> = ({ editRowId, isViewMode }) => {
  const attachments = useAppSelector((state) => state.insideSales.attachmentsList.results);
  const isLoading = useAppSelector((state) => state.insideSales.attachmentsList.isLoading);

  const dispatch = useAppDispatch();

  const clearAllAttachments = () => {
    cancelGetInsideSalesAttachmentList();
    dispatch(clearAttachmentsAction());
  };

  const getAttachmentsList = () => {
    if (!editRowId) return;

    dispatch(getInsideSalesAttachmentListAction({
      insideSalesRequestId: editRowId,
      controller: attachmentsController,
    }));
  };

  useEffect(() => {
    clearAllAttachments();
    attachmentsController = HTTPService.getController();

    if (!editRowId) {
      return;
    }

    getAttachmentsList();

    return clearAllAttachments;
  }, [editRowId]);

  if (!editRowId) {
    return null;
  }

  const clearAttachmentsErrors = () => {
    dispatch(clearAttachmentsErrorsAction());
  };

  const editAttachment = (attachmentId: string | number, newAttachment: IDraftAttachment) => {
    dispatch(editInsideSalesAttachmentItemAction({
      insideSalesRequestId: editRowId,
      attachmentId,
      attachmentData: newAttachment,
    }));
  };

  const createAttachment = (attachmentFile) => {
    dispatch(createInsideSalesAttachmentAction({
      insideSalesRequestId: editRowId,
      attachmentFile,
    }));
  };

  const removeAttachment = (attachmentId: number) => {
    dispatch(deleteInsideSalesAttachmentItemAction({
      insideSalesRequestId: editRowId,
      attachmentId,
    }));
  };

  const viewModeConfig = {
    [FileCategory.ATTACHMENT]: true,
    [FileCategory.PROPOSAL]: isViewMode as boolean,
  };

  const insideSalesAttachmentsConfig = getInsideSalesAttachmentsConfig(viewModeConfig);

  return (
    <Paper
      className="inside-sales-edit-row-sidebar__attachments"
      elevation={3}
    >
      <Attachments
        attachments={attachments}
        isLoading={isLoading}
        editAttachment={editAttachment}
        createAttachment={createAttachment}
        removeAttachment={removeAttachment}
        clearAttachmentsErrors={clearAttachmentsErrors}
        config={insideSalesAttachmentsConfig}
      />
    </Paper>
  );
};

InsideSalesEditAttachments.defaultProps = {
  isViewMode: false,
};

export default InsideSalesEditAttachments;
