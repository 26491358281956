import React, { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import ConfirmDialog from 'shared/ConfirmDialog';
import IconButtonWithTooltip from 'shared/IconButtonWithTooltip';

interface IEditSaveControlsProps {
  onSave: () => void;
  onCancelEdit: () => void;
  isCreateMode?: boolean;
  isHidden?: boolean;
  isDisabled?: boolean;
  isEditMode: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
}

const EditSaveControls: React.FC<IEditSaveControlsProps> = ({
  onSave, onCancelEdit, isCreateMode, isHidden, isEditMode, setIsEditMode, isDisabled,
}) => {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const toggleToEditMode = () => {
    setIsEditMode(true);
  };

  const toggleToViewMode = () => {
    setIsConfirmDialogOpen(true);
  };

  const onCancelConfirmDialog = () => {
    setIsConfirmDialogOpen(false);
  };

  const onConfirmDialogConfirm = () => {
    setIsConfirmDialogOpen(false);
    setIsEditMode(false);
    onCancelEdit();
  };

  if (isHidden) {
    return null;
  }

  if (isCreateMode) {
    return (
      <IconButtonWithTooltip
        title="Save"
        onClick={onSave}
        disabled={isDisabled as boolean}
      >
        <SaveIcon />
      </IconButtonWithTooltip>
    );
  }

  if (!isEditMode) {
    return (
      <IconButtonWithTooltip
        title="Edit"
        onClick={toggleToEditMode}
        disabled={isDisabled as boolean}
      >
        <EditIcon />
      </IconButtonWithTooltip>
    );
  }

  return (
    <>
      <IconButtonWithTooltip
        title="Save"
        onClick={onSave}
        disabled={isDisabled as boolean}
      >
        <SaveIcon />
      </IconButtonWithTooltip>
      <IconButtonWithTooltip
        title="Cancel"
        onClick={toggleToViewMode}
        disabled={isDisabled as boolean}
      >
        <ClearIcon />
      </IconButtonWithTooltip>
      <ConfirmDialog
        visible={isConfirmDialogOpen}
        onCancel={onCancelConfirmDialog}
        onConfirm={onConfirmDialogConfirm}
      />
    </>
  );
};

EditSaveControls.defaultProps = {
  isHidden: false,
  isCreateMode: false,
  isDisabled: false,
};

export default EditSaveControls;
