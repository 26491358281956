import {
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAILURE,
} from './actionTypes';

const getUserProfileReducer = (state = { data: {} }, {
  type, data, error,
}) => {
  switch (type) {
    case GET_USER_PROFILE_REQUEST:
      return {
        ...state,
        data: {},
        isLoading: true,
      };
    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        data,
        isLoading: false,
      };
    case GET_USER_PROFILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error,
      };
    default:
      return state;
  }
};

export default getUserProfileReducer;
