/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import { getSalesSowOrderForm, putSalesSowOrderForm } from 'core/api/sales.api';

export interface IState {
  result: {
    product?: string,
    [key: string]: unknown,
  };
  isLoading: boolean;
}

const initialState: IState = {
  result: {},
  isLoading: false,
};

export const getSalesSowOrderFormAction = createAsyncThunk(
  'getSalesSowOrderFormAction',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const { data } = await getSalesSowOrderForm(id);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  });

export const putSalesSowOrderFormAction = createAsyncThunk(
  'putSalesSowOrderFormAction',
  async ({ id, params }: { id: string | number, params: object }, { rejectWithValue }) => {
    try {
      const { data } = await putSalesSowOrderForm(id, params);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  });

const salesSowOrderFormSlice = createSlice({
  name: 'salesSowOrderForm',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        getSalesSowOrderFormAction.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.result = action.payload;
        })
      .addCase(
        getSalesSowOrderFormAction.rejected,
        (state) => {
          state.isLoading = false;
          state.result = {};
        })
      .addMatcher(
        isAnyOf(putSalesSowOrderFormAction.rejected, putSalesSowOrderFormAction.fulfilled),
        (state) => {
          state.isLoading = false;
        })
      .addMatcher(
        isAnyOf(getSalesSowOrderFormAction.pending, putSalesSowOrderFormAction.pending),
        (state) => {
          state.isLoading = true;
        },
      );
  },
});

export const salesSowOrderFormReducer = salesSowOrderFormSlice.reducer;
