import { netsparkAxios } from 'core/services/HTTPService';
import { IEngagementsTotalItem } from 'pages/Dashboard/Dashboard.models';
import { getEngagementsTotalOrdersUrl } from 'pages/Dashboard/api/apiUrls';
// eslint-disable-next-line no-unused-vars
import { TPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';

const getEngagementsTotalOrders = (signal: AbortSignal, params: TPeriod) => netsparkAxios
  .get<IEngagementsTotalItem[]>(getEngagementsTotalOrdersUrl, { signal, params });

export default getEngagementsTotalOrders;
