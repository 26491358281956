import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { columns, productUrlParam, customerCompanyNameUrlParam } from '@dashboardEscalationSupport/EscalationSupportTable/constants';
import EscalationSupportTable from '@dashboardEscalationSupport/EscalationSupportTable/EscalationSupportTable';
import { TableProvider } from 'shared/Table/TableContext';
import { tableIds } from 'shared/Table/constants';
import getEscalationSupportDataAction from '@dashboardEscalationSupport/EscalationSupportTable/GetEscalationSupport/actions';
import EditRowEscalationSupportForm from '@dashboardEscalationSupport/EscalationSupportTable/EditRowEscalationSupportForm';
import { TableActiveRowContextProvider } from 'shared/Table/TableActiveRowContext';

const EscalationSupportTableContainer = () => {
  const [searchParams] = useSearchParams();

  const product = searchParams.get(productUrlParam)?.split(',') || [];
  const customerCompanyName = searchParams.get(customerCompanyNameUrlParam)?.split(',') || [];

  const requestParams = {
    product: product[0],
    customerCompanyName: customerCompanyName[0],
  };

  return (
    <TableActiveRowContextProvider>
      <TableProvider
        id={tableIds.escalationSupport}
        columns={columns}
        getDataAction={getEscalationSupportDataAction}
        enableEditing
        requestParams={requestParams}
      >
        <EscalationSupportTable />
        <EditRowEscalationSupportForm />
      </TableProvider>
    </TableActiveRowContextProvider>
  );
};

export default EscalationSupportTableContainer;
