import React, { useState, useEffect } from 'react';
import { HTTPService } from 'core/services';
import { defaultPeriod } from '@dashboardWirelinePMDepartment/constants';
import { Box } from '@mui/material';
import RequestTypeChart from '@dashboardWirelineBillingDepartment/RequestTypeChart';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useAppDispatch } from 'core/store';
import FiltersByPeriod from 'pages/Dashboard/shared/FiltersByPeriod';
import { getRequestTypeAction } from '@dashboardWirelineBillingDepartment/GetRequestType';
import { notifierMessage } from '@dashboardWirelineBillingDepartment/constants';
import BillingEngagementStatusChart from 'pages/Dashboard/BusinessDirections/Wireline/WirelineDepartments/BillingDepartment/BillingEngagementStatusChart';
import { getBillingEngagementStatusAction } from '@dashboardWirelineBillingDepartment/GetBillingEngagementStatus';

import './BillingDepartment.scss';

let requestTypeController = HTTPService.getController();
let billingEngagementStatusController = HTTPService.getController();

const BillingDepartment = () => {
  const { showErrorNotifier } = useNotifiers();

  const dispatch = useAppDispatch();

  const [period, setPeriod] = useState(defaultPeriod);

  const cancelRequestTypeRequest = () => {
    HTTPService.cancelRequest(requestTypeController);
  };

  const cancelBillingEngagementStatusRequest = () => {
    HTTPService.cancelRequest(billingEngagementStatusController);
  };

  const getRequestType = (newPeriod) => {
    cancelRequestTypeRequest();

    requestTypeController = HTTPService.getController();

    dispatch(getRequestTypeAction({
      controller: requestTypeController, params: newPeriod,
    }))
      .unwrap()
      .catch((e) => {
        showErrorNotifier(notifierMessage.requestType.error, e);
      });
  };

  const getBillingEngagementStatus = (newPeriod) => {
    cancelBillingEngagementStatusRequest();

    billingEngagementStatusController = HTTPService.getController();

    dispatch(getBillingEngagementStatusAction({
      controller: billingEngagementStatusController, params: newPeriod,
    }))
      .unwrap()
      .catch((e) => {
        showErrorNotifier(notifierMessage.billingEngagementStatus.error, e);
      });
  };

  const updateCharts = (newPeriod) => {
    getRequestType(newPeriod);
    getBillingEngagementStatus(newPeriod);

    setPeriod(newPeriod);
  };

  const cancelRequests = () => {
    cancelRequestTypeRequest();
    cancelBillingEngagementStatusRequest();
  };

  useEffect(() => {
    updateCharts(period);

    return () => cancelRequests();
  }, []);

  return (
    <Box className="billing-specialist">
      <Box className="billing-specialist__filters">
        <FiltersByPeriod
          period={period}
          onChange={updateCharts}
        />
      </Box>
      <Box className="billing-specialist__charts">
        <RequestTypeChart />
        <BillingEngagementStatusChart />
      </Box>
    </Box>
  );
};

export default BillingDepartment;
