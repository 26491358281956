import {
  GET_GENERAL_ENGAGEMENT_REQUEST,
  GET_GENERAL_ENGAGEMENT_SUCCESS,
  GET_GENERAL_ENGAGEMENT_FAILURE,
} from '@dashboardWirelinePMDepartment/GetGeneralEngagement/actionsTypes';
import { IEngagementsOrdersByPeriodItem } from 'pages/Dashboard/Dashboard.models';

interface IGetGeneralEngagementState {
  data: IEngagementsOrdersByPeriodItem[];
  isLoading: boolean;
  error: object;
}

const initialState: IGetGeneralEngagementState = {
  isLoading: false,
  data: [],
  error: {},
};

const getGeneralEngagementReducer = (state = initialState, {
  type, data, error,
}) => {
  if (type === GET_GENERAL_ENGAGEMENT_REQUEST) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (type === GET_GENERAL_ENGAGEMENT_SUCCESS) {
    return {
      ...state,
      data,
      isLoading: false,
    };
  }

  if (type === GET_GENERAL_ENGAGEMENT_FAILURE) {
    return {
      ...state,
      isLoading: false,
      error,
      data: [],
    };
  }

  return state;
};
export default getGeneralEngagementReducer;
