import { netsparkAxios } from 'core/services/HTTPService';
import { IEngagementsOrdersByPeriodItem } from 'pages/Dashboard/Dashboard.models';
import { getEngagementsOrdersMrcUrl } from 'pages/Dashboard/api/apiUrls';
// eslint-disable-next-line no-unused-vars
import { TPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';

const getEngagementsOrdersMrc = (signal: AbortSignal, params: TPeriod) => netsparkAxios
  .get<IEngagementsOrdersByPeriodItem[]>(getEngagementsOrdersMrcUrl, { signal, params });

export default getEngagementsOrdersMrc;
