import React from 'react';
import EscalationSupportTable from '@dashboardEscalationSupport/EscalationSupportTable';
import EscalationSupportCharts from '@dashboardEscalationSupport/EscalationSupportCharts';

const EscalationSupport = () => (
  <>
    <EscalationSupportCharts />
    <EscalationSupportTable />
  </>
);

export default EscalationSupport;
