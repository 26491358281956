/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import { getSalesActiveNumbersById, patchSalesActiveNumbersById } from 'core/api/sales.api';

export interface IState {
  result: object;
  isLoading: boolean;
}

const initialState: IState = {
  result: {},
  isLoading: false,
};

export const getSalesActiveNumbersItemAction = createAsyncThunk(
  'getSalesActiveNumbersItemAction',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const { data } = await getSalesActiveNumbersById(id);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  });

export const patchSalesActiveNumbersItemAction = createAsyncThunk(
  'patchSalesActiveNumbersItemAction',
  async ({ id, params }: { id: string | number, params: object }, { rejectWithValue }) => {
    try {
      const { data } = await patchSalesActiveNumbersById(id, params);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  });

const salesActiveNumbersItemSlice = createSlice({
  name: 'salesActiveNumbersItem',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        getSalesActiveNumbersItemAction.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.result = action.payload;
        })
      .addCase(
        getSalesActiveNumbersItemAction.rejected,
        (state) => {
          state.isLoading = false;
          state.result = {};
        })
      .addMatcher(
        isAnyOf(
          patchSalesActiveNumbersItemAction.rejected,
          patchSalesActiveNumbersItemAction.fulfilled,
        ),
        (state) => {
          state.isLoading = false;
        })
      .addMatcher(
        isAnyOf(getSalesActiveNumbersItemAction.pending, patchSalesActiveNumbersItemAction.pending),
        (state) => {
          state.isLoading = true;
        },
      );
  },
});

export const salesActiveNumbersItemReducer = salesActiveNumbersItemSlice.reducer;
