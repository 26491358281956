import React from 'react';
import ReactRouterPrompt from 'react-router-prompt';
import ConfirmDialog from 'shared/ConfirmDialog';

interface IRouterFormConfirmDialogProps {
  when: boolean
}

const RouterFormConfirmDialog: React.FC<
  IRouterFormConfirmDialogProps
> = ({ when }) => (
  <ReactRouterPrompt when={when}>
    {({ isActive, onConfirm, onCancel }) => (
      <ConfirmDialog visible={isActive} onConfirm={onConfirm} onCancel={onCancel} />
    )}
  </ReactRouterPrompt>
);

export default RouterFormConfirmDialog;
