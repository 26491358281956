import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';
import { Grid } from '@mui/material';

import { getSalesSowOrderFormAction, putSalesSowOrderFormAction } from 'core/actions/Sales/SalesSowOrderForm';
import { useAppDispatch, useAppSelector } from 'core/store';
import { IServerValidationError } from 'core/services/HTTPService.models';
import Loader from 'shared/Loader';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import RouterFormConfirmDialogFormik from 'shared/RouterFormConfirmDialog/RouterFormConfirmDialogFormik';

import RequestOrderFormFormGeneral from '@EngagementsSowData/RequestOrderForm/RequestOrderFormFormGeneral';
import RequestOrderFormContacts from '@EngagementsSowData/RequestOrderForm/RequestOrderFormContacts/RequestOrderFormContacts';
import RequestOrderFormButtons from '@EngagementsSowData/RequestOrderForm/RequestOrderFormButtons';
import {
  loadRequestOrderDataNotifierMessage,
  saveRequestOrderNotifierMessage,
} from '@EngagementsSowData/RequestOrderForm/constants';

import './RequestOrderForm.scss';

interface IRequestOrderFormProps {
  orderId: string,
  isViewMode?: boolean,
  isEditMode?: boolean,
  setIsEditMode?: ((value: boolean) => void) | undefined,
}

const RequestOrderForm: React.FC<
  IRequestOrderFormProps
> = ({
  orderId, isViewMode, isEditMode, setIsEditMode,
}) => {
  const dispatch = useAppDispatch();
  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();

  const salesSowOrderForm = useAppSelector(
    (state) => state.wirelineEngagements.salesSowOrderForm.result,
  );
  const isLoadingOrderForm = useAppSelector(
    (state) => state.wirelineEngagements.salesSowOrderForm.isLoading,
  );
  const isLoadingRequestOrders = useAppSelector(
    (state) => state.wirelineEngagements.requestOrders.isLoading,
  );
  const isLoadingSchemas = useAppSelector((state) => state.openapiSchemas.isLoading);
  const isLoadingAction = isLoadingOrderForm || isLoadingRequestOrders;

  const loadSalesSowOrderForm = (
    setValues?: (values: React.SetStateAction<unknown>, shouldValidate?: boolean) => void,
  ) => {
    dispatch(getSalesSowOrderFormAction(orderId))
      .unwrap()
      .then((newValues) => {
        setIsEditMode?.(false);
        setValues?.(newValues);
      })
      .catch((e) => {
        showErrorNotifier(loadRequestOrderDataNotifierMessage.error, e);
      });
  };

  useEffect(() => {
    loadSalesSowOrderForm();
  }, [orderId]);

  const onSubmit = async (values: any, { setErrors }) => {
    dispatch(putSalesSowOrderFormAction({
      id: orderId,
      params: values,
    }))
      .unwrap()
      .then(() => {
        setIsEditMode?.(false);
        showSuccessNotifier(saveRequestOrderNotifierMessage.success);
      })
      .catch((error) => {
        const definedError = error as IServerValidationError;
        setErrors(definedError?.response?.data);
        showErrorNotifier(saveRequestOrderNotifierMessage.error, error);
      });
  };

  if (isLoadingSchemas) {
    return <Loader />;
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={salesSowOrderForm}
      validateOnBlur
      validateOnChange
      enableReinitialize
    >
      <Form data-testid="request-order-form">
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          columnSpacing={3}
        >
          {isLoadingAction && <Loader />}
          {!isViewMode && setIsEditMode && (
            <RequestOrderFormButtons
              orderId={orderId}
              isEditMode={isEditMode as boolean}
              setIsEditMode={setIsEditMode}
              onCancelEdit={loadSalesSowOrderForm}
            />
          )}
          <RequestOrderFormFormGeneral
            isEditMode={isEditMode as boolean}
          />
          <RequestOrderFormContacts
            isEditMode={isEditMode as boolean}
          />
        </Grid>
        <RouterFormConfirmDialogFormik />
      </Form>
    </Formik>
  );
};

RequestOrderForm.defaultProps = {
  isViewMode: false,
  isEditMode: false,
  setIsEditMode: undefined,
};

export default RequestOrderForm;
