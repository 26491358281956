import React from 'react';
import CustomDialogWrapper from 'shared/CustomDialog';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { updateCustomerSeller } from 'core/api/user.api';
import { useTableContext } from 'shared/Table/TableContext';
import { ICustomer } from 'pages/Customers/Customers.models';
import { IServerValidationError } from 'core/services/HTTPService.models';
import { updateCustomerNotifications } from 'pages/Customers/CustomersList/contants';
import ReassignSellerForm from 'pages/Customers/CustomersList/ReassignSellerModal/ReassignSellerForm';
// eslint-disable-next-line no-unused-vars
import { IReassignSellerEdit } from 'pages/Customers/CustomersList/ReassignSellerModal/ReassignSellerModal.models';
import './ReassignSellerModal.scss';

interface IReassignSellerModal {
  activeCustomerData: ICustomer | null
  setActiveCustomerData: (customerData: null) => void
}

const ReassignSellerModal: React.FC<IReassignSellerModal> = ({
  activeCustomerData,
  setActiveCustomerData,
}) => {
  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();

  const { getTableData } = useTableContext();

  const isModalOpen = !!activeCustomerData;
  const { id, sellerId } = activeCustomerData || {};

  const onCloseModal = () => setActiveCustomerData(null);

  const onReassignSeller = async (params: IReassignSellerEdit, { setErrors, setSubmitting }) => {
    try {
      await updateCustomerSeller(id as number, params);

      getTableData();
      onCloseModal();
      showSuccessNotifier(updateCustomerNotifications.success);
    } catch (error) {
      const definedError = error as IServerValidationError;

      setErrors(definedError?.response?.data);
      showErrorNotifier(updateCustomerNotifications.error, error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <CustomDialogWrapper
      isConfirmDialog
      isModalOpen={isModalOpen}
      onCloseModal={onCloseModal}
      className="reassign-seller-modal"
      renderChildren={
        (onClose) => (
          <ReassignSellerForm
            onClose={onClose}
            sellerId={sellerId as number}
            onSubmitHandler={onReassignSeller}
          />
        )
      }
    />
  );
};

export default ReassignSellerModal;
