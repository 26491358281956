import { Status } from '@EngagementsSowData/sowData.model';
import { ITableCell } from 'shared/Table/Table.models';

export interface IEngagementsTotalItem {
    sellerFullname: string,
    potentialProfit: number,
}

export interface IEngagementsOrdersByPeriodItem {
    year: number;
    month: number;
    new: number;
    canceled: number;
    inProgress: number;
    completed: number;
    reOrder: number;
}

export interface IEngagementsOrdersByPeriodItemChart extends IEngagementsOrdersByPeriodItem {
    xDateKey: string;
}

export enum DASHBOARD_TABS {
    WIRELINE = 'wireline',
    MOBILITY = 'mobility',
    CUSTOMER_SUPPORT_REQUEST = 'customer_support_request',
    ESCALATION_SUPPORT = 'escalation_support',
    CUSTOMER_EMAILING = 'customer_emailing',
}

export enum SELLER_DEPARTMENT_TABS {
    WIRELINE_SALES = 'wireline_sales',
    WIRELINE_INSIDE_SALES = 'wireline_inside_sales',
    WIRELINE_CONTRACT_SPECIALIST = 'wireline_contract_specialist',
    WIRELINE_PROJECT_MANAGER = 'wireline_project_manager',
    WIRELINE_BILLING = 'wireline_billing',
    WIRELINE_RETENTION = 'wireline_retention',
}

export interface IBookingDataItem {
    seller: string
    contractSpecialist: string
    billingSpecialist: string
    dataBooked: string
    customerName: string
    oppIds: string
    // eslint-disable-next-line no-restricted-globals
    location: string
    bandwidth: string
    product: string
    // eslint-disable-next-line no-restricted-globals
    status: Status | null,
    mrc: string | null,
    nrc: string | null,
    signed: string | null
}

export type ITableCellBookingDataItem = ITableCell<IBookingDataItem>

export interface IEscalationSupportDataItem {
    companyName: string,
    escalationType: string,
    // eslint-disable-next-line no-restricted-globals
    location: string,
    product: string,
    createdBy: string,
    // eslint-disable-next-line no-restricted-globals
    status: string,
    details: string,
}
