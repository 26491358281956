import { FileCategory } from 'pages/InsideSales/model';
import { insideSalesCategoryNames } from 'pages/InsideSales/InsideSalesList/InsideSalesListTable/InsideSalesListTableModals/InsideSalesEditRowSidebarForm/InsideSalesEditAttachments/constants';

type ViewModeConfig = {
    [key in FileCategory]: boolean;
};

export const getInsideSalesAttachmentsConfig = (viewModeConfig: ViewModeConfig) => (
  [
    {
      tabName: insideSalesCategoryNames.attachments,
      category: FileCategory.ATTACHMENT,
      isViewMode: viewModeConfig[FileCategory.ATTACHMENT],
    },
    {
      tabName: insideSalesCategoryNames.Proposals,
      category: FileCategory.PROPOSAL,
      isViewMode: viewModeConfig[FileCategory.PROPOSAL],
    },
  ]
);
