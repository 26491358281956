import {
  GET_RENEWAL_SOW_REQUEST,
  GET_RENEWAL_SOW_SUCCESS,
  GET_RENEWAL_SOW_FAILURE,
} from 'core/actions/Sales/GetSalesRenewalSow/actionsTypes';
import { IRenewalSowDataItem } from 'pages/WirelineEngagements/WirelineEngagementsTables/WirelineEngagementsRenewalSowData/renewalSowData.models';

interface IRenewalSowState {
  isLoading: boolean,
  count: number,
  results: IRenewalSowDataItem[],
  error: object | null,
}

const initialState: IRenewalSowState = {
  isLoading: true,
  count: 0,
  results: [],
  error: {},
};

const getRenewalSowReducer = (state = initialState, {
  type, data, error,
}) => {
  if (type === GET_RENEWAL_SOW_REQUEST) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (type === GET_RENEWAL_SOW_SUCCESS) {
    return {
      ...state,
      ...data,
      isLoading: false,
    };
  }

  if (type === GET_RENEWAL_SOW_FAILURE) {
    return {
      ...state,
      isLoading: false,
      error,
    };
  }

  return state;
};

export default getRenewalSowReducer;
