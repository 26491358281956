import React from 'react';
import map from 'lodash/map';
import { useAppSelector } from 'core/store';

import DashboardBarChart from 'pages/Dashboard/shared/DashboardBarChart';
// eslint-disable-next-line no-unused-vars
import { IEngagementsOrdersByPeriodItemChart } from 'pages/Dashboard/Dashboard.models';
import { colorsOfTotalCharts, totalChartFields } from '@dashboardWirelineSellerDepartment/constants';
import { unitUSD } from 'core/constants';
import { getMonthName } from 'pages/Dashboard/shared/utilities';

const EngagementsMrcChart = () => {
  const engagementsMrc = useAppSelector((state) => state.dashboard.engagementsMrc.data);
  const isLoading = useAppSelector((state) => state.dashboard.engagementsMrc.isLoading);

  const data = map(engagementsMrc, (item) => ({
    ...item,
    xDateKey: getMonthName(item?.month),
  }));

  return (
    <DashboardBarChart<IEngagementsOrdersByPeriodItemChart>
      data={data}
      colors={colorsOfTotalCharts}
      fields={totalChartFields}
      title={`MRC, ${unitUSD}`}
      unit={unitUSD}
      isLoading={isLoading}
      xAxisLabel="MON"
    />
  );
};

export default EngagementsMrcChart;
