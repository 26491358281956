import React from 'react';
import { useFormikContext } from 'formik';
import CustomModal from 'shared/CustomModal';
import CustomersCreateQuickFormFields from 'pages/Customers/CustomersCreate/CustomersCreateQuickForm/CustomersCreateQuickFormFields';
import {
  useWirelineEngagementsCreateContext,
} from 'pages/WirelineEngagements/WirelineEngagementsCreate/WirelineEngagementsCreateContext';

const CustomersCreateQuickFormModal = () => {
  const { submitForm, isValid } = useFormikContext();

  const {
    isCreateCustomerModalOpen,
    setIsCreateCustomerModalOpen,
  } = useWirelineEngagementsCreateContext();

  return (
    <CustomModal
      isModalOpen={isCreateCustomerModalOpen}
      title="Create Customer"
      onCloseModal={setIsCreateCustomerModalOpen}
      areButtonsVisible
      confirmButtonDisable={!isValid}
      onClickConfirm={submitForm}
      isConfirmDialog
    >
      <CustomersCreateQuickFormFields />
    </CustomModal>
  );
};

export default CustomersCreateQuickFormModal;
