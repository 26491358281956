/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { postInsideSalesDraft, postInsideSalesUpdateDraft } from 'core/api/insideSales.api';
// eslint-disable-next-line no-unused-vars
import { IInsideSalesDraftItem } from 'pages/InsideSales/model';

export const postInsideSalesItemDraftAction = createAsyncThunk(
  'postInsideSalesDraftAction',
  async (
    { controller } : { controller: AbortController },
    { rejectWithValue },
  ) => {
    const { signal } = controller;

    try {
      const { data } = await postInsideSalesDraft(signal);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const postInsideSalesItemUpdateDraftAction = createAsyncThunk(
  'postInsideSalesItemUpdateDraftAction',
  async (
    { id, params }: { id: string | number, params: IInsideSalesDraftItem },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await postInsideSalesUpdateDraft(id, params);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export interface IState {
  id: number | undefined;
  isLoading: boolean;
}

const initialState: IState = {
  id: undefined,
  isLoading: false,
};

const insideSalesItemDraftSlice = createSlice({
  name: 'insideSalesItemDraftSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        postInsideSalesItemDraftAction.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.id = action.payload.id;
        },
      )
      .addCase(
        postInsideSalesItemUpdateDraftAction.fulfilled, (state) => {
          state.isLoading = false;
          state.id = undefined;
        },
      )
      .addMatcher(
        isAnyOf(
          postInsideSalesItemDraftAction.rejected,
          postInsideSalesItemUpdateDraftAction.rejected,
        ),
        (state) => {
          state.isLoading = false;
        },
      )
      .addMatcher(
        isAnyOf(
          postInsideSalesItemDraftAction.pending,
          postInsideSalesItemUpdateDraftAction.pending,
        ),
        (state) => {
          state.isLoading = true;
        },
      );
  },
});

export const insideSalesItemDraftReducer = insideSalesItemDraftSlice.reducer;
