import React, { useState, useEffect } from 'react';
import { useAppDispatch } from 'core/store';
import { tableIds } from 'shared/Table/constants';
import { TableProvider } from 'shared/Table/TableContext';
import { columns } from '@dashboardWireline/WirelineDepartments/constants';
import BookingTable from '@dashboardWirelineSellerDepartment/BookingInfo/BookingTable';
import { checkIfOrderNonAttTSD } from 'pages/WirelineEngagements/WirelineEngagementsTables/utilities';
import getBookingTableDataAction from '@dashboardWirelineSellerDepartment/GetBookingTableData/actions';
import { clearBookingSellerDataAction } from '@dashboardWirelineSellerDepartment/GetBookingSellerData';
import { clearBookingReportDataAction } from '@dashboardWirelineSellerDepartment/GetBookingReportData';
import { useBookingTableColumns } from '@dashboardWireline/WirelineDepartments/useBookingTableColumns';
import { useBookingPermission } from '@dashboardWirelineSellerDepartment/BookingInfo/useBookingPermission';
// eslint-disable-next-line no-unused-vars
import { BookingStatus, ServiceProductType, IBookingReportPeriod } from '@dashboardWireline/WirelineDepartments/WIrelineDepartament.models';
import './BookingInfo.scss';

const BookingInfo = () => {
  const dispatch = useAppDispatch();

  const { bookingColumns, isLoading: isLoadingTSDsList } = useBookingTableColumns(columns);

  const [reportPeriod, setReportPeriod] = useState<string | null>(null);

  const { isActionsAvailable } = useBookingPermission();

  const enableRowSelection = isActionsAvailable && (
    (row) => {
      const isBookedOrderStatus = row.original.bookingStatus === BookingStatus.BOOKED;
      const isTransactionalOrderStatus = row.original.bookingStatus === BookingStatus.TRANSACTIONAL;
      const isTransactionalServiceProductType = row.original.serviceProductType
        === ServiceProductType.TRANSACTIONAL;
      const isNonAttOrder = checkIfOrderNonAttTSD(row.original);

      return !isBookedOrderStatus
        && !isTransactionalOrderStatus
        && !isNonAttOrder
        && !isTransactionalServiceProductType;
    }
  );

  const requestParams = reportPeriod ? { bookingReportPeriod: reportPeriod } : undefined;

  useEffect(() => () => {
    dispatch(clearBookingSellerDataAction());
    dispatch(clearBookingReportDataAction());
  }, []);

  return (
    <TableProvider<IBookingReportPeriod>
      columns={bookingColumns}
      requestParams={requestParams}
      id={tableIds.bookingSellerInfo}
      enableRowSelection={enableRowSelection}
      getDataAction={getBookingTableDataAction}
    >
      <BookingTable
        reportPeriod={reportPeriod}
        setReportPeriod={setReportPeriod}
        isLoadingTableFiltersData={isLoadingTSDsList}
      />
    </TableProvider>
  );
};

export default BookingInfo;
