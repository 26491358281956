import {
  getEngagementsRevenueRequest,
  getEngagementsRevenueSuccess,
  getEngagementsRevenueFailure,
} from './actionsCreators';
import getEngagementsRevenue from './api.service';

const getEngagementsRevenueAction = (controller, params) => async (dispatch) => {
  try {
    const { signal } = controller;
    dispatch(getEngagementsRevenueRequest());
    const { data } = await getEngagementsRevenue(signal, params);
    dispatch(getEngagementsRevenueSuccess(data));
  } catch (error) {
    dispatch(getEngagementsRevenueFailure(error));

    throw error;
  }
};

export default getEngagementsRevenueAction;
