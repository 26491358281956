import React, { useState } from 'react';
import { mapValues } from 'lodash';
import {
  Bar, BarChart, CartesianGrid, Label, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,
  LabelList,
} from 'recharts';
import { useMediaQuery, useTheme } from '@mui/material';
import CustomizedPeriodXAxisTick from 'pages/Dashboard/shared/CustomizedPeriodXAxisTick';
import ChartTooltip from 'pages/Dashboard/shared/ChartTooltip';
import { chartNoData, cursorColor } from 'pages/Dashboard/shared/constants';
import DashboardBarChartSkeleton from 'pages/Dashboard/shared/DashboardBarChart/DashboardBarChartSkeleton';
import { LabelPosition } from 'core/models/Recharts.models';
import './DashboardBarChart.scss';

type TFields = {
  [key: string]: string;
}

interface IDashboardBarChart<T> {
  colors: string[];
  title: string;
  data: T[];
  fields: TFields;
  unit?: string,
  xAxisLabel?: string
  totalFieldName?: string
  isLoading?: boolean
  enableShorthandXAxis?: boolean
  needInsideLabel?: boolean
  barLabel?: { label?: { position: LabelPosition } }
}

const DashboardBarChart = <T extends {}>({
  data, fields, colors, title, unit, isLoading, xAxisLabel, totalFieldName,
  enableShorthandXAxis, needInsideLabel, barLabel,
}: IDashboardBarChart<T>) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const listOfFields = Object.keys(fields);

  const initialBarState = mapValues(fields, () => false);

  const [barProps, setBarProps] = useState(initialBarState);

  const selectBar = (e) => {
    setBarProps({
      ...barProps,
      [e.dataKey]: !barProps[e.dataKey],
    });
  };

  const barChartContent = () => {
    if (isLoading) {
      return <DashboardBarChartSkeleton />;
    }

    if (!data?.length) {
      return chartNoData;
    }

    const xDataKey = isSmallScreen && enableShorthandXAxis ? 'xDateKeyMobile' : 'xDateKey';

    return (
      <ResponsiveContainer width="99%" height={300}>
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: isMobile ? 15 : 30,
            left: isMobile ? -20 : 20,
            bottom: 5,
          }}
        >
          <CartesianGrid vertical={false} strokeDasharray="4 4" />
          <XAxis
            dataKey={xDataKey}
            tick={(props) => <CustomizedPeriodXAxisTick {...props} />}
            padding={{ left: 30 }}
          >
            {xAxisLabel && (
            <Label
              value={xAxisLabel}
              offset={-20}
              dy={4}
              position="insideLeft"
            />
            )}
          </XAxis>
          <YAxis axisLine={false} tickLine={false} />
          <Tooltip
            cursor={{ fill: cursorColor }}
            content={<ChartTooltip unit={unit as string} />}
          />
          <Legend
            iconType="circle"
            iconSize={8}
            onClick={selectBar}
          />
          {listOfFields.map((field, index) => (
            <Bar
              key={field}
              dataKey={field}
              name={fields[field]}
              fill={colors[index % colors.length]}
              maxBarSize={25}
              hide={barProps[field]}
              stackId="a"
              {...barLabel}
            >
              {needInsideLabel && <LabelList dataKey={field} position={LabelPosition.INSIDE} />}
              {
                index === listOfFields.length - 1 && totalFieldName && (
                  <LabelList
                    position="top"
                    valueAccessor={(entry) => entry[totalFieldName]}
                  />
                )
              }
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
    );
  };

  return (
    <div className="dashboard-bar-chart">
      <h3 className="dashboard-bar-chart__title">
        {title}
      </h3>
      {barChartContent()}
    </div>
  );
};

DashboardBarChart.defaultProps = {
  unit: undefined,
  xAxisLabel: undefined,
  totalFieldName: undefined,
  isLoading: false,
  enableShorthandXAxis: false,
  needInsideLabel: false,
  barLabel: {},
};
export default DashboardBarChart;
