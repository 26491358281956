import React, { useMemo, useEffect, useState } from 'react';
import { noop } from 'lodash';
import { createOrder } from 'core/api/sales.api';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useAppDispatch, useAppSelector } from 'core/store';
import { clearTSDsAction, getTSDsAction } from 'core/actions/Sales/GetTSDs';
import CustomDialogSteperContent from 'shared/CustomDialog/CustomDialogSteperContent';
import EngagementOrderForm from 'pages/WirelineEngagements/shared/AddOrderModalForm/EngagementsOrderForm';
import AddOrderRequestTypeForm from 'pages/WirelineEngagements/shared/AddOrderModalForm/AddOrderRequestTypeForm';
import { clearProductCategoriesAction, getProductCategoriesAction } from 'core/actions/Sales/GetProductCategories';
import { getOrdersToSubmit, getUpdatedOrdersByRemovedProducts } from 'pages/WirelineEngagements/shared/AddOrderModalForm/utilites';
import { getLocationsByCustomerIdAction, clearLocationsByCustomerIdAction } from 'core/actions/Customers/GetLocationsByCustomerId';
import createOrderNotifier, { ATAndTLabel, fields, nonATAndTCategoryLabel } from 'pages/WirelineEngagements/shared/AddOrderModalForm/constants';
import {
// eslint-disable-next-line no-unused-vars
  ICreateOrderItem, IFormikProductValues, IProductParams, IFormRequestTypeProductItem,
  IFormikOrder,
  IFormRequestTypeValues,
} from 'pages/WirelineEngagements/shared/AddOrderModalForm/product.model';

interface IAddProductModalProps {
  customerId: number,
  onClose: () => void;
  onSubmitSuccessCallback?: (() => void) | undefined
  setIsAddProductModalOpen: (value: boolean) => void
}

const AddProductModal: React.FC<IAddProductModalProps> = ({
  customerId, onClose, onSubmitSuccessCallback, setIsAddProductModalOpen,
}) => {
  const dispatch = useAppDispatch();
  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();

  const TSDList = useAppSelector((state) => state.wirelineEngagements.TSDs.data);
  const productCategories = useAppSelector(
    (state) => state.wirelineEngagements.productCategories.data,
  );

  const isTSDsLoading = useAppSelector((state) => state.wirelineEngagements.TSDs.isLoading);
  const isLocationsLoading = useAppSelector(
    (state) => state.customers.locationsByCustomerId.isLoading,
  );
  const isCategoriesLoading = useAppSelector(
    (state) => state.wirelineEngagements.productCategories.isLoading,
  );

  const [orderData, setOrderData] = useState<IFormikProductValues | null>(null);
  const [
    productsRequestTypeFormData,
    setProductsRequestTypeFormData,
  ] = useState<IFormRequestTypeValues | null>(null);

  const isLoadind = isTSDsLoading || isCategoriesLoading || isLocationsLoading;

  const attTSDId = useMemo(
    () => TSDList.find((item) => item.name === ATAndTLabel)?.id,
    [TSDList],
  );

  const { attCategoriesIds, nonATTCategoriesIds } = useMemo(
    () => productCategories.reduce<{attCategoriesIds: number[], nonATTCategoriesIds: number[]}>((
      accumulator, current,
    ) => (current.name === nonATAndTCategoryLabel
      ? { ...accumulator, nonATTCategoriesIds: [current.id] }
      : { ...accumulator, attCategoriesIds: [...accumulator.attCategoriesIds, current.id] }),
    { attCategoriesIds: [], nonATTCategoriesIds: [] }),
    [productCategories]);

  const getLocations = () => {
    try {
      dispatch(getLocationsByCustomerIdAction(customerId))
        .unwrap();
    } catch (e) {
      showErrorNotifier('Failed to load locations', e);
    }
  };

  const getProductCategories = () => {
    try {
      dispatch(getProductCategoriesAction())
        .unwrap();
    } catch (e) {
      showErrorNotifier('Failed to load product categories', e);
    }
  };

  const getTSDs = () => {
    try {
      dispatch(getTSDsAction())
        .unwrap();
    } catch (e) {
      showErrorNotifier('Failed to load TSD', e);
    }
  };

  const onSubmit = async (values: IFormRequestTypeValues) => {
    const orders = getOrdersToSubmit(values);

    try {
      await createOrder(orders);

      showSuccessNotifier(createOrderNotifier.success);
      setIsAddProductModalOpen(false);
      onSubmitSuccessCallback?.();
    } catch (e) {
      showErrorNotifier(createOrderNotifier.error, e);
    }
  };

  const onRemoveProducts = (products: IFormRequestTypeProductItem[]) => {
    const updatedOrders = getUpdatedOrdersByRemovedProducts(
      orderData?.[fields.orders] as IFormikOrder[],
      products,
    );
    const hasOrdersWithCurrentTSD = updatedOrders.some((
      item,
    ) => item.tsdId === orderData?.currentTSD);

    if (hasOrdersWithCurrentTSD) {
      setOrderData({
        ...orderData as IFormikProductValues,
        orders: updatedOrders,
      });
    } else {
      setOrderData({
        [fields.currentTSD]: updatedOrders[0].tsdId,
        [fields.orders]: updatedOrders,
      });
    }
  };

  const onGoToPreviousStepHandler = (
    data: IFormRequestTypeValues,
    goToPreviousStep: () => void,
  ) => {
    setProductsRequestTypeFormData(data);
    goToPreviousStep();
  };

  useEffect(() => {
    getTSDs();
    getLocations();
    getProductCategories();

    return () => {
      dispatch(clearTSDsAction());
      dispatch(clearProductCategoriesAction());
      dispatch(clearLocationsByCustomerIdAction());
    };
  }, []);

  return (
    <CustomDialogSteperContent
      onClose={onClose}
      maxWidth="100%"
      title="Add order"
      isLoading={isLoadind}
      className="add-order-modal-form"
      steps={(goToNextStep, goToPreviousStep) => [
        <EngagementOrderForm
          onClose={onClose}
          attTSDId={attTSDId}
          initialData={orderData}
          onGoToNextStep={goToNextStep}
          onSetOrderData={setOrderData}
          attCategoriesIds={attCategoriesIds}
          nonATTCategoriesIds={nonATTCategoriesIds}
        />,
        <AddOrderRequestTypeForm
          onSubmit={onSubmit}
          ordersData={orderData}
          attTSDId={attTSDId as number}
          onRemoveProducts={onRemoveProducts}
          productsRequestTypeFormData={productsRequestTypeFormData}
          onGoToPreviousStep={(data) => onGoToPreviousStepHandler(data, goToPreviousStep)}
        />,
      ]}
    />
  );
};

AddProductModal.defaultProps = {
  onSubmitSuccessCallback: noop,
};

export default AddProductModal;
