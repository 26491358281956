import React from 'react';
import map from 'lodash/map';
import { useAppSelector } from 'core/store';

import DashboardBarChart from 'pages/Dashboard/shared/DashboardBarChart';
// eslint-disable-next-line no-unused-vars
import { IEngagementsOrdersByPeriodItemChart } from 'pages/Dashboard/Dashboard.models';
import { colorsOfTotalCharts, totalChartFields } from '@dashboardWirelineSellerDepartment/constants';
import { getMonthName } from 'pages/Dashboard/shared/utilities';

const EngagementsByPeriodChart = () => {
  const engagementsByPeriod = useAppSelector((state) => state.dashboard.engagementsByPeriod.data);
  const isLoading = useAppSelector((state) => state.dashboard.engagementsByPeriod.isLoading);

  const data = map(engagementsByPeriod, (item) => ({
    ...item,
    xDateKey: getMonthName(item?.month),
  }));

  return (
    <DashboardBarChart<IEngagementsOrdersByPeriodItemChart>
      data={data}
      isLoading={isLoading}
      colors={colorsOfTotalCharts}
      fields={totalChartFields}
      title="By Period"
      xAxisLabel="MON"
    />
  );
};

export default EngagementsByPeriodChart;
