import React from 'react';
import { Grid } from '@mui/material';
import { duplicateOrderNotifier } from '@EngagementsSowData/constants';
import CustomModal from 'shared/CustomModal';
import OrderModalItem from 'shared/OrderModalItem';
import { useAppDispatch } from 'core/store';
import { useTableContext } from 'shared/Table/TableContext';
import { TableRowMode } from 'shared/Table/Table.models';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import postDuplicateOrderAction from './DuplicateOrder';

import './DuplicateOrderModal.scss';

const DuplicateOrderModal = ({ activeRowMode, activeRow, cleanActiveRow }) => {
  const dispatch = useAppDispatch();
  const { getTableData } = useTableContext();
  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();

  const duplicateOrderRowId = activeRow?.id;
  const isDuplicateOrderMode = activeRowMode === TableRowMode.DUPLICATE;

  const submit = async () => {
    try {
      await dispatch(postDuplicateOrderAction({
        id: duplicateOrderRowId,
      })).unwrap();
      showSuccessNotifier(duplicateOrderNotifier.success);
      cleanActiveRow();
      getTableData();
    } catch (e) {
      showErrorNotifier(duplicateOrderNotifier.error, e);
    }
  };

  if (!duplicateOrderRowId || !isDuplicateOrderMode) {
    return null;
  }

  return (
    <CustomModal
      title="Are you sure you want to duplicate the selected order?"
      isModalOpen={!!activeRow}
      onClickConfirm={submit}
      onCloseModal={cleanActiveRow}
      areButtonsVisible
      maxWidth="504px"
      isConfirmDialog
      confirmButtonText="Yes"
      cancelButtonText="No"
    >
      <Grid
        className="duplicate-order__modal"
        container
        item
      >
        <OrderModalItem
          location={activeRow.customerLocation || activeRow.location}
          product={activeRow.product}
        />
      </Grid>
    </CustomModal>
  );
};

export default DuplicateOrderModal;
