import { netsparkAxios } from 'core/services/HTTPService';
import {
  getInsideSalesAttachmentsItemUrl,
  getInsideSalesAttachmentsUrl,
  getInsideSalesRequestDraftUpdateUrl,
  getInsideSalesRequestListItem,
  getPostInsideSalesCancelRequestUrl,
  getSetInsideSalesManagerUrl,
  insideSalesRequestDraftPath,
  insideSalesRequestPath,
} from 'core/api/constants';
import { IRequestPaging } from 'core/services/HTTPService.models';
import {
// eslint-disable-next-line no-unused-vars
  IInsideSalesDraftItem,
  IInsideSalesListItem,
  IInsideSalesRequestAttachment,
  // eslint-disable-next-line no-unused-vars
  IInsideSalesRequestCancelRequest,
} from 'pages/InsideSales/model';

export const getInsideSales = (signal: AbortSignal, params) => (
  netsparkAxios.get<IRequestPaging<IInsideSalesListItem>>(
    insideSalesRequestPath,
    { signal, params },
  )
);

export const getInsideSalesItem = (signal: AbortSignal, id: number | string) => (
  netsparkAxios.get<IInsideSalesListItem>(
    getInsideSalesRequestListItem(id),
    { signal },
  )
);

export const patchInsideSalesItem = (id: number | string, params) => (
  netsparkAxios.patch(
    getInsideSalesRequestListItem(id),
    params,
  )
);

export const postInsideSalesDraft = (signal: AbortSignal) => (
  netsparkAxios.post<{ id: number }>(
    insideSalesRequestDraftPath,
    {},
    { signal },
  )
);

export const postInsideSalesUpdateDraft = (id: number | string, params: IInsideSalesDraftItem) => (
  netsparkAxios.post<IInsideSalesListItem>(
    getInsideSalesRequestDraftUpdateUrl(id),
    params,
  )
);

export const createInsideSalesAttachment = (
  insideSalesRequestId: string | number,
  attachmentFile: FormData,
) => (
  netsparkAxios.post(getInsideSalesAttachmentsUrl(insideSalesRequestId), attachmentFile)
);

export const getInsideSalesAttachmentList = (
  signal: AbortSignal, insideSalesRequestId: string | number,
) => (
  netsparkAxios.get<
    IInsideSalesRequestAttachment[]
  >(getInsideSalesAttachmentsUrl(insideSalesRequestId), { signal })
);

export const editInsideSalesAttachmentItem = (
  insideSalesRequestId: string | number,
  attachmentId: string | number,
  attachmentData: unknown,
) => (
  netsparkAxios.patch(
    getInsideSalesAttachmentsItemUrl(insideSalesRequestId, attachmentId),
    attachmentData,
  )
);

export const deleteInsideSalesAttachmentItem = (
  insideSalesRequestId: string | number,
  attachmentId: string | number,
) => (
  netsparkAxios.delete(getInsideSalesAttachmentsItemUrl(insideSalesRequestId, attachmentId))
);

export const postSetInsideSalesManager = (id: number | string) => (
  netsparkAxios.post(getSetInsideSalesManagerUrl(id))
);

export const postCancelInsideSalesRequest = (
  id: number | string,
  params: IInsideSalesRequestCancelRequest,
) => (
  netsparkAxios.post(getPostInsideSalesCancelRequestUrl(id), params)
);
