import React from 'react';

import { useAppSelector } from 'core/store';

import DashboardPieChart from 'pages/Dashboard/shared/DashboardPieChart';
import { colorsOfTotalCharts } from '@dashboardWirelineSellerDepartment/constants';
import { engagementsTotalOrdersMapper } from '@dashboardWirelineSellerDepartment/EngagementsTotalsChart/constants';

const EngagementsTotalsChart = () => {
  const engagementsTotalOrders = useAppSelector((state) => state.dashboard.engagementsTotals.data);
  const isLoading = useAppSelector((state) => state.dashboard.engagementsTotals.isLoading);
  const chartData = engagementsTotalOrders.map((item) => ({
    ...item,
    name: engagementsTotalOrdersMapper[item.name],
  }));

  return (
    <DashboardPieChart
      colors={colorsOfTotalCharts}
      data={chartData}
      isLoading={isLoading}
      innerRadius={60}
      paddingAngle={5}
      title="Total"
    />
  );
};

export default EngagementsTotalsChart;
