import { useIsUserType } from 'core/hooks/useIsUserType';
import useIsUserTeamRoleName from 'core/hooks/useIsUserTeamRoleName';
import useIsTeamRolesDepartmentMember from 'core/hooks/useIsTeamRolesDepartmentMember';
import { UserTeamRoleName, UserTeamRoleTeamDepartment, UserType } from 'pages/UserManagement/UserManagement.models';

const { ADMIN } = UserType;
const { DEPARTMENT_HEAD } = UserTeamRoleName;
const { PROJECT_MANAGER } = UserTeamRoleTeamDepartment;

export const useBookingPermission = () => {
  const isAdmin = useIsUserType(ADMIN);

  const isProjectManager = useIsTeamRolesDepartmentMember(PROJECT_MANAGER);
  const isDepartmentHead = useIsUserTeamRoleName(DEPARTMENT_HEAD);

  const isProjectManagerDepartmentHead = isProjectManager && isDepartmentHead;

  const isActionsAvailable = isAdmin || isProjectManagerDepartmentHead;

  return {
    isActionsAvailable,
  };
};
