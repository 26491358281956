// eslint-disable-next-line no-unused-vars
import React, { FC, useMemo } from 'react';
import merge from 'lodash/merge';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import { Grid, Box, Hidden } from '@mui/material';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'core/store';
import { createCustomerFromDraftAction } from 'core/actions/Customers/CreateCustomerFromDraft/actions';
import { IServerValidationError } from 'core/services/HTTPService.models';
import paths from 'core/routing/paths';
import Loader from 'shared/Loader';
import { IOption } from 'shared/FormikField/FormikField.model';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { createCustomerNotifierMessage } from 'pages/Customers/CustomersCreate/constants';
import { ICustomer } from 'pages/Customers/Customers.models';
import RouterFormConfirmDialogFormik from 'shared/RouterFormConfirmDialog/RouterFormConfirmDialogFormik';

import schema from './CustomersCreateFormSchema';
import CustomersCreateFormCustomerInformation from './CustomersCreateFormSections/CustomersCreateFormCustomerInformation';
import CustomersCreateFormBillingInformation from './CustomersCreateFormSections/CustomersCreateFormBillingInformation';
import CustomersCreateFormLOAInformation from './CustomersCreateFormSections/CustomersCreateFormLOAInformation';
import CustomersCreateFormProfile from './CustomersCreateFormSections/CustomersCreateFormProfile';
import CustomersCreateFormButtons from './CustomersCreateFormButtons';
import CustomersWidgets from '../../CustomersWidgets';

import './CustomersCreateForm.scss';

const HiddenXlDownGrid = () => (
  <Hidden xlDown>
    <Grid item xl={2} />
  </Hidden>
);

interface ICustomersCreateFormProps {
  id: number
  initialSeller?: string
  sellersOptions: IOption[]
  customer?: ICustomer | null
}

const CustomersCreateForm: FC<ICustomersCreateFormProps> = ({
  id, sellersOptions, customer, initialSeller,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoadingCustomer = useAppSelector((state) => state.customers.createCustomer.isLoading);
  const isLoadingUsers = useAppSelector((state) => state.customers.users.isLoading);
  const isLoading = isLoadingCustomer || isLoadingUsers;
  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();
  const isEditMode = !!customer;

  const onSubmit = async (params, { setErrors }) => {
    try {
      await dispatch(createCustomerFromDraftAction(id, params));
      showSuccessNotifier(createCustomerNotifierMessage.sucsess);
      navigate(paths.customersList);
    } catch (error) {
      const definedError = error as IServerValidationError;
      setErrors(definedError?.response?.data);

      showErrorNotifier(createCustomerNotifierMessage.error, error);
    }
  };

  const initialValues = useMemo(() => {
    const defaultValues = schema.getDefault();

    const customerWithoutNull = omitBy(customer, isNil);

    return merge(
      defaultValues,
      customerWithoutNull,
      { id, ...(initialSeller && { seller: initialSeller }) },
    );
  }, []);

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validateOnBlur
      validateOnChange
    >
      <Form>
        <Box className="customers-create-form__wrapper">
          <Grid
            className="customers-create-form__section"
            container
            columnSpacing={5}
          >
            {isLoading && <Loader />}
            <HiddenXlDownGrid />
            <CustomersCreateFormCustomerInformation
              isEditMode={isEditMode}
              sellersOptions={sellersOptions}
            />
            <CustomersCreateFormBillingInformation />
            <HiddenXlDownGrid />
            <HiddenXlDownGrid />
            <CustomersCreateFormLOAInformation />
            <CustomersCreateFormProfile
              title="contract signer"
              sectionFieldName="contractSignerProfile"
            />
            <HiddenXlDownGrid />
            <HiddenXlDownGrid />
            <CustomersCreateFormProfile
              title="primary it"
              sectionFieldName="primaryItProfile"
            />
            <CustomersCreateFormProfile
              title="alternative contact"
              sectionFieldName="alternativeContactProfile"
            />
            <HiddenXlDownGrid />
          </Grid>
        </Box>
        <CustomersWidgets id={id} isLoading={isLoading} />
        <CustomersCreateFormButtons isEditMode={isEditMode} />
        <RouterFormConfirmDialogFormik />
      </Form>
    </Formik>
  );
};

CustomersCreateForm.defaultProps = {
  customer: null,
  initialSeller: '',
};

export default CustomersCreateForm;
