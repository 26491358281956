import React from 'react';
import { useAppSelector } from 'core/store';
import VerticalBarChart
  from 'pages/Dashboard/shared/VerticalBarChart/VerticalBarChart';

import './EngagementsRevenueChart.scss';

const EngagementsRevenueChart = () => {
  const engagementsRevenueData = useAppSelector((state) => state.dashboard.engagementsRevenue.data);
  const isLoading = useAppSelector((state) => state.dashboard.engagementsRevenue.isLoading);

  return (
    <VerticalBarChart
      data={engagementsRevenueData}
      isLoading={isLoading}
      title="By Revenue"
    />
  );
};

export default EngagementsRevenueChart;
