import React, { useState } from 'react';
import AttachmentList from 'shared/widgets/Attachments/AttachmentList';
import Dropzone from 'shared/widgets/Attachments/Dropzone';
import {
  createInsideSalesAttachmentAction,
  editInsideSalesAttachmentItemAction,
  clearAttachmentsErrorsAction,
  deleteInsideSalesAttachmentItemAction,
} from 'core/actions/crm/InsideSales/InsideSalesAttachments';
import { useAppDispatch, useAppSelector } from 'core/store';
import { IAttachmentInEditMode } from 'shared/widgets/Attachments/Attachments.model';
import { FileCategory } from 'pages/InsideSales/model';

const InsideSalesAttachments = () => {
  const draftId = useAppSelector((state) => state.insideSales.draft.id);
  const attachments = useAppSelector((state) => state.insideSales.attachmentsList.results);
  const isLoading = useAppSelector((state) => state.insideSales.attachmentsList.isLoading);

  const [numberDownloadFiles, setNumberDownloadFiles] = useState(attachments.length);
  const [editAttachment, setEditAttachment] = useState<IAttachmentInEditMode | null>(null);

  const dispatch = useAppDispatch();

  if (!draftId) {
    return null;
  }

  const clearRedactionAttachment = () => {
    setEditAttachment(null);
  };

  const clearAttachmentsErrors = () => {
    dispatch(clearAttachmentsErrorsAction());
  };

  const onEditAttachment = (attachmentId, newName) => {
    dispatch(editInsideSalesAttachmentItemAction({
      insideSalesRequestId: draftId,
      attachmentId,
      attachmentData: {
        name: newName,
        category: FileCategory.ATTACHMENT,
      },
    }));
  };

  const createAttachment = (attachmentFile) => {
    dispatch(createInsideSalesAttachmentAction({
      insideSalesRequestId: draftId,
      attachmentFile,
    }));
  };

  const onDrop = (acceptedFiles) => {
    clearAttachmentsErrors();
    setNumberDownloadFiles(acceptedFiles.length);

    acceptedFiles.forEach((file) => {
      const attachmentFile = new FormData();
      attachmentFile.append('file', file);
      attachmentFile.append('category', FileCategory.ATTACHMENT);
      createAttachment(attachmentFile);
    });
  };

  const focusAttachment = (attachment) => {
    setEditAttachment({ name: attachment.name, id: attachment.id });
    clearAttachmentsErrors();
  };

  const changeAttachmentName = (e) => {
    if (!editAttachment) return;

    const name = e.target.value;
    setEditAttachment({ ...editAttachment, name });
  };

  const editAttachmentName = (attachmentId) => {
    if (!editAttachment) return;

    onEditAttachment(attachmentId, editAttachment.name);
    clearRedactionAttachment();
  };

  const removeAttachment = (attachmentId: number) => {
    dispatch(deleteInsideSalesAttachmentItemAction({
      insideSalesRequestId: draftId,
      attachmentId,
    }));
  };

  return (
    <div className="inside-sales__create-form__attachments">
      <Dropzone
        onDrop={onDrop}
        isAddButton={false}
      />
      {
        attachments.length > 0 && (
          <AttachmentList
            attachments={attachments}
            attachmentInEditMode={editAttachment}
            focusAttachment={focusAttachment}
            changeAttachmentName={changeAttachmentName}
            editAttachmentName={editAttachmentName}
            removeAttachment={removeAttachment}
            numberDownloadFiles={numberDownloadFiles}
            isLoadingAttachments={isLoading}
          />
        )
      }
    </div>

  );
};

export default InsideSalesAttachments;
