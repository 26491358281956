import React, { useState, ReactElement } from 'react';
import cx from 'classnames';
import { Dialog, PaperProps } from '@mui/material';
import ConfirmDialog from 'shared/ConfirmDialog';

interface ICustomDialogWrapper {
  isModalOpen: boolean;
  onCloseModal: (isModalOpen: boolean) => void;
  renderChildren: (onClose: () => void) => ReactElement;
  className?: string;
  isConfirmDialog?: boolean;
  paperProps?: PaperProps
}

const CustomDialogWrapper: React.FC<ICustomDialogWrapper> = ({
  isModalOpen, onCloseModal, className, renderChildren, paperProps, isConfirmDialog, ...props
}) => {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const onCloseConfirmDialog = () => {
    setIsConfirmDialogOpen(false);
  };

  const onConfirmDialogConfirm = () => {
    onCloseConfirmDialog();
    onCloseModal(false);
  };

  const onClose = () => (isConfirmDialog
    ? setIsConfirmDialogOpen(true)
    : onCloseModal(false));

  return (
    <>
      <Dialog
        onClose={onClose}
        open={isModalOpen}
        data-testid="custom-dialog-wrapper"
        PaperProps={paperProps as PaperProps}
        className={cx(className, 'custom-dialog-wrapper')}
        {...props}
      >
        {renderChildren(onClose)}
      </Dialog>
      <ConfirmDialog
        visible={isConfirmDialogOpen}
        onCancel={onCloseConfirmDialog}
        onConfirm={onConfirmDialogConfirm}
      />
    </>
  );
};

CustomDialogWrapper.defaultProps = {
  paperProps: {},
  className: '',
  isConfirmDialog: false,
};

export default CustomDialogWrapper;
