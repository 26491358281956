import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import CustomersLocations from 'pages/Customers/CustomersLocations';
import useIsUserTeamRoleName from 'core/hooks/useIsUserTeamRoleName';
import useIsTeamRolesDepartmentMember from 'core/hooks/useIsTeamRolesDepartmentMember';
import WirelineEngagementsComments from 'pages/WirelineEngagements/WirelineEngagementsComments';
import WirelineEngagementsAttachments from 'pages/WirelineEngagements/WirelineEngagementsAttachments';
import { UserTeamRoleName, UserTeamRoleTeamDepartment } from 'pages/UserManagement/UserManagement.models';
import { useWirelineEngagementsCreateContext } from 'pages/WirelineEngagements/WirelineEngagementsCreate/WirelineEngagementsCreateContext';

const { DEPARTMENT_HEAD } = UserTeamRoleName;
const {
  BILLING_SPECIALIST, PROJECT_MANAGER, CONTRACT_SPECIALIST, RETENTION_MANAGER,
} = UserTeamRoleTeamDepartment;

const WirelineEngagementsWidgets = () => {
  const { isInitializing, initialCustomerId } = useWirelineEngagementsCreateContext();
  const { wirelineEngagementId } = useParams();

  const isDepartmentHead = useIsUserTeamRoleName(DEPARTMENT_HEAD);
  const isContractSpecialist = useIsTeamRolesDepartmentMember(CONTRACT_SPECIALIST);
  const isProjectManager = useIsTeamRolesDepartmentMember(PROJECT_MANAGER);
  const isRetentionManager = useIsTeamRolesDepartmentMember(RETENTION_MANAGER);
  const isBillingSpecialist = useIsTeamRolesDepartmentMember(BILLING_SPECIALIST);
  const isProjectManagerNotDepartmentHead = isProjectManager && !isDepartmentHead;

  const isLocationsWidgetAvailable = !(
    isContractSpecialist
    || isRetentionManager
    || isBillingSpecialist
    || isProjectManagerNotDepartmentHead
  );

  if (isInitializing || !wirelineEngagementId || !initialCustomerId) {
    return null;
  }

  return (
    <Box mb={3}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={isLocationsWidgetAvailable ? 4 : 6}>
          <WirelineEngagementsComments
            engagementId={wirelineEngagementId}
          />
        </Grid>
        <Grid item xs={12} md={isLocationsWidgetAvailable ? 4 : 6}>
          <WirelineEngagementsAttachments
            engagementId={wirelineEngagementId}
          />
        </Grid>
        {
          isLocationsWidgetAvailable && (
            <Grid item xs={12} md={4}>
              <CustomersLocations
                customerId={initialCustomerId}
              />
            </Grid>
          )
        }
      </Grid>
    </Box>
  );
};

export default WirelineEngagementsWidgets;
