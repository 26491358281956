import React from 'react';
import cx from 'classnames';
import { renewalStatusLabels } from '@EngagementsRenewalSowData/constants';
import { RenewalStatusSow } from '@EngagementsRenewalSowData/renewalSowData.models';

const RenewalStatus = ({ contractStatus }) => {
  const statusClassName = cx({
    declined: contractStatus === RenewalStatusSow.DECLINED,
    progress: contractStatus === RenewalStatusSow.IN_PROGRESS,
    renewed: contractStatus === RenewalStatusSow.RENEWED,
  });

  return (
    <div className={`contract-status ${statusClassName}`}>
      {renewalStatusLabels[contractStatus]}
    </div>
  );
};

export default RenewalStatus;
