// eslint-disable-next-line no-unused-vars
import React, { FC } from 'react';
import { Grid, InputLabel } from '@mui/material';
import { GRID_DIRECTION } from 'shared/FormikField/FormikField.model';

interface IFormikFieldWrapperProps {
  children: React.ReactNode
  fieldName: string
  label?: string | undefined
  gridDirection?: GRID_DIRECTION
  required?: boolean
}

const FormikFieldWrapper: FC<IFormikFieldWrapperProps> = ({
  children, fieldName, required, gridDirection, label,
}) => {
  const isColumnDirection = gridDirection === GRID_DIRECTION.COLUMN;

  return (
    <Grid
      item
      container
      rowSpacing={1}
    >
      {label && (
        <Grid
          item
          justifyContent="center"
          xs={12}
          sm={isColumnDirection ? 12 : 4}
        >
          <InputLabel
            htmlFor={fieldName}
            required={required as boolean}
          >
            {label}
          </InputLabel>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        sm={isColumnDirection || !label ? 12 : 8}
      >
        {children}
      </Grid>
    </Grid>
  );
};

FormikFieldWrapper.defaultProps = {
  gridDirection: GRID_DIRECTION.ROW,
  required: false,
  label: '',
};

export default FormikFieldWrapper;
