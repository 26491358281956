import React from 'react';
import filter from 'lodash/filter';
import { Grid, Typography } from '@mui/material';
import { useAppSelector } from 'core/store';
import { parseOpenapiSchema } from 'core/utilities/openapiSchema';
import { locationFields, profileFields } from '@EngagementsSowData/RequestOrderForm/constants';
import FormikFieldsFromSchema from 'pages/WirelineEngagements/shared/FormikFieldsFromSchema';
import { dynamicProductSchemaName } from '@EngagementsSowData/constants';

interface IRequestOrderFormFormGeneralProps {
  isEditMode: boolean
}

const staticFields = [
  ...profileFields.map(({ fieldName }) => fieldName),
  ...locationFields.map(({ fieldName }) => fieldName),
];

const RequestOrderFormFormGeneral: React.FC<
  IRequestOrderFormFormGeneralProps
> = ({ isEditMode }) => {
  const openApiSchemas = useAppSelector((state) => state.openapiSchemas.schemas);
  const salesSowOrderForm = useAppSelector(
    (state) => state.wirelineEngagements.salesSowOrderForm.result,
  );

  const retrieveSchema = parseOpenapiSchema(
    openApiSchemas,
    dynamicProductSchemaName,
  );

  const filteredRetrieveSchema = filter(
    retrieveSchema,
    ({ fieldName }) => !staticFields.includes(fieldName),
  );

  return (
    <Grid
      className="request-order-form__section"
      item
      container
      md={6}
    >
      <Grid
        item
        container
        xs={12}
        pb={3}
      >
        <Typography
          className="request-order-form__section-title"
          variant="body1"
          component="p"
        >
          General
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        pb={3}
        rowSpacing={3}
      >
        <FormikFieldsFromSchema
          retrieveSchema={
            filteredRetrieveSchema.filter((schemaItem) => schemaItem.fieldName in salesSowOrderForm)
          }
          isDisabled={!isEditMode}
          isTooltip
        />
      </Grid>
    </Grid>
  );
};

export default RequestOrderFormFormGeneral;
