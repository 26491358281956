import React, { useMemo, useRef } from 'react';
import { Formik, FormikProps } from 'formik';
import { useAppDispatch } from 'core/store';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import CustomDialogStep from 'shared/CustomDialog/CustomDialogStep';
import { fields } from 'pages/WirelineEngagements/shared/AddOrderModalForm//constants';
import { getProductsAction } from 'core/actions/Sales/GetProducts';
import { getProductParams } from 'pages/WirelineEngagements/shared/AddOrderModalForm/utilites';
// eslint-disable-next-line no-unused-vars
import { IFormikProductValues, IProductParams } from 'pages/WirelineEngagements/shared/AddOrderModalForm/product.model';
import EngagementsOrderItem from 'pages/WirelineEngagements/shared/AddOrderModalForm/EngagementsOrderForm/EngagementsOrderItem';
import getEngagementsOrderFormSchema, { engagementsOrderFormDefaultData } from 'pages/WirelineEngagements/shared/AddOrderModalForm/EngagementsOrderForm/EngagementsOrderFormSchema';

interface IEngagementOrderProps {
  onClose: () => void
  onGoToNextStep: () => void
  attCategoriesIds: number[]
  nonATTCategoriesIds: number[]
  attTSDId?: number | undefined
  initialData: IFormikProductValues | null
  onSetOrderData: (data: IFormikProductValues) => void
}

const EngagementOrder: React.FC<IEngagementOrderProps> = ({
  onClose,
  attTSDId,
  initialData,
  onGoToNextStep,
  onSetOrderData,
  attCategoriesIds,
  nonATTCategoriesIds,
}) => {
  const formikRef = useRef<FormikProps<IFormikProductValues>>(null);
  const { showErrorNotifier } = useNotifiers();
  const dispatch = useAppDispatch();

  const initialValues = useMemo(
    () => {
      if (initialData) return initialData;

      return attTSDId
        ? {
          currentTSD: String(attTSDId),
          orders: [{
            ...engagementsOrderFormDefaultData.orders[0],
            [fields.tsdId]: String(attTSDId),
          }],
        }
        : engagementsOrderFormDefaultData;
    },
    [attTSDId]);

  const validationShema = useMemo(
    () => getEngagementsOrderFormSchema(String(attTSDId)),
    [attTSDId],
  );

  const getProducts = (params: IProductParams) => {
    try {
      dispatch(getProductsAction(params))
        .unwrap();
    } catch (e) {
      showErrorNotifier('Failed to load products', e);
    }
  };

  const getNonATTProducts = (itemCount: number) => {
    const params = getProductParams({
      categories: nonATTCategoriesIds,
      data: formikRef?.current?.values?.orders?.[itemCount],
    });

    getProducts(params);
  };

  const getATTProducts = (itemCount: number) => {
    const params = getProductParams({
      categories: attCategoriesIds,
      data: formikRef?.current?.values?.orders?.[itemCount],
    });

    getProducts(params);
  };

  const onSubmit = (values) => {
    onSetOrderData(values);
    onGoToNextStep();
  };

  return (
    <Formik
      validateOnBlur
      validateOnChange
      enableReinitialize
      onSubmit={onSubmit}
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationShema}
    >
      { ({ submitForm }) => (
        <CustomDialogStep
          onClickFirstButton={submitForm}
          onClickSecondButton={onClose}
          firstButtonText="Next"
        >
          <EngagementsOrderItem
            attTSDId={attTSDId}
            getATTProducts={getATTProducts}
            getNonATTProducts={getNonATTProducts}
            nonATTCategoriesIds={nonATTCategoriesIds}
          />
        </CustomDialogStep>
      )}
    </Formik>
  );
};

EngagementOrder.defaultProps = {
  attTSDId: undefined,
};

export default EngagementOrder;
