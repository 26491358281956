import React from 'react';
import { Box, Grid } from '@mui/material';
import CustomersComments from 'pages/Customers/CustomersComments';
import CustomersAttachments from 'pages/Customers/CustomersAttachments';
import CustomersLocations from 'pages/Customers/CustomersLocations';

interface ICustomersWidgetsProps {
    id: number
    isLoading: boolean
}

const CustomersWidgets: React.FC<ICustomersWidgetsProps> = ({ id, isLoading }) => {
  if (isLoading || !id) {
    return null;
  }

  return (
    <Box mb={3}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <CustomersComments customerId={id} />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomersAttachments customerId={id} />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomersLocations customerId={id} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomersWidgets;
