import React from 'react';
import InsideSalesCreateModalForm from 'pages/InsideSales/InsideSalesList/InsideSalesListTable/InsideSalesListTableModals/InsideSalesCreateModalForm';
import InsideSalesEditRowSidebarForm from 'pages/InsideSales/InsideSalesList/InsideSalesListTable/InsideSalesListTableModals/InsideSalesEditRowSidebarForm';
import InsideSalesAcceptedModal from 'pages/InsideSales/InsideSalesList/InsideSalesListTable/InsideSalesListTableModals/InsideSalesAcceptedModal';
import CancelRequestModalForm from 'pages/InsideSales/InsideSalesList/InsideSalesListTable/InsideSalesListTableModals/CancelRequestModalForm';

interface IInsideSalesListTableModalsProps {
  isCreateRequestModalOpen: boolean
  setIsCreateRequestModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  isInsideSalesAcceptedModalOpen: boolean
  setIsInsideSalesAcceptedModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const InsideSalesListTableModals: React.FC<
  IInsideSalesListTableModalsProps
> = ({
  isCreateRequestModalOpen,
  setIsCreateRequestModalOpen,
  isInsideSalesAcceptedModalOpen,
  setIsInsideSalesAcceptedModalOpen,
}) => (
  <>
    <InsideSalesCreateModalForm
      isOpen={isCreateRequestModalOpen}
      setIsOpen={setIsCreateRequestModalOpen}
    />
    <InsideSalesAcceptedModal
      isInsideSalesAcceptedModalOpen={isInsideSalesAcceptedModalOpen}
      setIsInsideSalesAcceptedModalOpen={setIsInsideSalesAcceptedModalOpen}
    />
    <InsideSalesEditRowSidebarForm />
    <CancelRequestModalForm />
  </>
);

export default InsideSalesListTableModals;
