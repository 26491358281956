import { useIsUserType } from 'core/hooks/useIsUserType';
import useIsUserTeamRoleName from 'core/hooks/useIsUserTeamRoleName';
import useIsTeamRolesDepartmentMember from 'core/hooks/useIsTeamRolesDepartmentMember';
import { UserType, UserTeamRoleName, UserTeamRoleTeamDepartment } from 'pages/UserManagement/UserManagement.models';

const { DEPARTMENT_HEAD } = UserTeamRoleName;
const { SELLER, INSIDE_SALES, PROJECT_MANAGER } = UserTeamRoleTeamDepartment;

const useInsideSalesListPermissions = () => {
  const isAdmin = useIsUserType(UserType.ADMIN);

  const isDepartmentHead = useIsUserTeamRoleName(DEPARTMENT_HEAD);

  const isSeller = useIsTeamRolesDepartmentMember(SELLER);
  const isInsideSales = useIsTeamRolesDepartmentMember(INSIDE_SALES);
  const isProjectManager = useIsTeamRolesDepartmentMember(PROJECT_MANAGER);

  const isRequestAcceptedEnabled = isInsideSales || isAdmin;

  const isProjectManagerNotDepartmentHead = isProjectManager && isDepartmentHead;

  const isFieldViewEnabled = !isSeller;
  const isAbleToMarkRequestAsCompleted = isAdmin || isSeller;
  const isRowActionEnabled = isAdmin || isSeller || isInsideSales;
  const isCreateEnabled = isAdmin || isSeller || isProjectManagerNotDepartmentHead;
  const isCancelRequestEnabled = isAdmin || isSeller || isProjectManagerNotDepartmentHead;

  return {
    isCreateEnabled,
    isFieldViewEnabled,
    isRowActionEnabled,
    isCancelRequestEnabled,
    isRequestAcceptedEnabled,
    isAbleToMarkRequestAsCompleted,
  };
};

export default useInsideSalesListPermissions;
