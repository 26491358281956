import { combineReducers } from '@reduxjs/toolkit';
import getInsideSalesListReducer from 'core/actions/crm/InsideSales/GetInsideSalesList/reducer';
import {
  insideSalesItemDraftReducer,
} from 'core/actions/crm/InsideSales/PostInsideSalesDraft/PostInsideSalesDraft';
import {
  InsideSalesAttachmentsReducer,
} from 'core/actions/crm/InsideSales/InsideSalesAttachments';
import { insideSalesListItemReducer } from 'core/actions/crm/InsideSales/InsideSalesListItem';
import { setInsideSalesManagerReducer } from 'core/actions/crm/InsideSales/PostSetInsideSalesManager';
import { CancelRequestReducer } from 'core/actions/crm/InsideSales/CancelRequest';

const insideSalesReducer = combineReducers({
  list: getInsideSalesListReducer,
  draft: insideSalesItemDraftReducer,
  attachmentsList: InsideSalesAttachmentsReducer,
  item: insideSalesListItemReducer,
  accepted: setInsideSalesManagerReducer,
  cancelRequest: CancelRequestReducer,
});

export default insideSalesReducer;
