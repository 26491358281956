import React from 'react';
import { useFormikContext } from 'formik';
import { useAppDispatch } from 'core/store';
import { clearAttachmentsAction } from 'core/actions/crm/InsideSales/InsideSalesAttachments';
import CustomModal from 'shared/CustomModal';
import InsideSalesCreateForm from 'pages/InsideSales/InsideSalesList/InsideSalesListTable/InsideSalesListTableModals/InsideSalesCreateModalForm/InsideSalesCreateForm';
import InsideSalesAttachments from 'pages/InsideSales/InsideSalesList/InsideSalesListTable/InsideSalesListTableModals/InsideSalesCreateModalForm/InsideSalesAttachments';

interface IInsideSalesCreateModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const InsideSalesCreateModal: React.FC<
  IInsideSalesCreateModalProps
> = ({ isOpen, setIsOpen }) => {
  const dispatch = useAppDispatch();

  const { submitForm, resetForm } = useFormikContext();

  const onCloseModal = () => {
    resetForm();
    setIsOpen(false);
    dispatch(clearAttachmentsAction());
  };

  return (
    <CustomModal
      title="Create request"
      isModalOpen={isOpen}
      onCloseModal={onCloseModal}
      onClickConfirm={submitForm}
      isConfirmDialog
      areButtonsVisible
    >
      <>
        <InsideSalesCreateForm />
        <InsideSalesAttachments />
      </>
    </CustomModal>
  );
};

export default InsideSalesCreateModal;
