import React from 'react';
import noop from 'lodash/noop';
import CustomDialogWrapper from 'shared/CustomDialog';
import AddOrderModal from 'pages/WirelineEngagements/shared/AddOrderModalForm/AddOrderModal';

import './AddOrderModalForm.scss';

interface IAddOrderModalFormProps {
  customerId: number
  isAddProductModalOpen: boolean
  setIsAddProductModalOpen: (value: boolean) => void
  onSubmitSuccessCallback?: () => void
}

const AddOrderModalForm: React.FC<IAddOrderModalFormProps> = ({
  customerId, isAddProductModalOpen, setIsAddProductModalOpen, onSubmitSuccessCallback,
}) => (
  <CustomDialogWrapper
    isConfirmDialog
    isModalOpen={isAddProductModalOpen}
    onCloseModal={setIsAddProductModalOpen}
    paperProps={{ sx: { width: '80%', maxWidth: '1162px', height: '656px' } }}
    renderChildren={(onClose) => (
      <AddOrderModal
        onClose={onClose}
        customerId={customerId}
        onSubmitSuccessCallback={onSubmitSuccessCallback}
        setIsAddProductModalOpen={setIsAddProductModalOpen}
      />
    )}
  />
);

AddOrderModalForm.defaultProps = {
  onSubmitSuccessCallback: noop,
};

export default AddOrderModalForm;
