import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { Button, Grid, Tooltip } from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import SaveIcon from '@mui/icons-material/Save';
import { useSearchParams } from 'react-router-dom';

import { requestOrdersAction } from 'core/actions/Sales/RequestOrders';
import { useAppDispatch, useAppSelector } from 'core/store';
import getSalesSowAction from 'core/actions/Sales/GetSalesSow/actions';
import { HTTPService } from 'core/services';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import noop from 'lodash/noop';
import ActionsModalRequestOrder
  from '@EngagementsSowData/WirelineEngagementsSowDataModals/ActionsModal/ActionsModalRequestOrder';

import { sendRequestOrderNotifierMessage } from '@EngagementsSowData/RequestOrderForm/constants';

const ordersInfoController = HTTPService.getController();

interface IRequestOrderFormButtonsProps {
  orderId: string,
  isEditMode: boolean
  setIsEditMode: (isEditMode: boolean) => void
  onCancelEdit: (
    setValues: (values: React.SetStateAction<unknown>, shouldValidate?: boolean) => void
  ) => void
}

const RequestOrderFormButtons: React.FC<
  IRequestOrderFormButtonsProps
> = ({
  orderId, isEditMode, setIsEditMode, onCancelEdit,
}) => {
  const dispatch = useAppDispatch();
  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();
  const [searchParams] = useSearchParams();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const { submitForm, setValues } = useFormikContext();

  const ordersToSend = useAppSelector((state) => state.wirelineEngagements.salesSow.results);
  const isRequestOrdersActionLoading = useAppSelector(
    (state) => state.wirelineEngagements.requestOrders.isLoading,
  );
  const orderToSend = ordersToSend.find(({ id }) => id === Number(orderId));
  const modalRequestOrdersToSend = orderToSend ? [orderToSend] : [];

  const isOrderCreated = !!orderToSend?.requestOrderActionDatetime;
  const isSendButtonDisabled = isOrderCreated || isEditMode;

  const onRequestOrder = () => {
    dispatch(requestOrdersAction([{ orderId: Number(orderId) }]))
      .unwrap()
      .then(() => {
        showSuccessNotifier(sendRequestOrderNotifierMessage.success);
        setIsEditMode(false);

        const orderIds = searchParams.get('orderIds')?.split(',') || [];

        const orderIdsParams = {
          page: 1,
          page_size: orderIds.length,
          orderId: orderIds.toString(),
        };

        dispatch(getSalesSowAction(ordersInfoController, orderIdsParams));
      })
      .catch((e) => {
        showErrorNotifier(sendRequestOrderNotifierMessage.error, e);
      })
      .finally(() => {
        setIsConfirmModalOpen(false);
      });
  };

  return (
    <Grid
      container
      direction="row"
      pb={2}
      pl={3}
    >
      <ActionsModalRequestOrder
        isModalOpen={isConfirmModalOpen}
        setIsModalOpen={setIsConfirmModalOpen}
        orders={modalRequestOrdersToSend}
        onConfirm={onRequestOrder}
      />
      {
        isEditMode && (
          <span>
            <Button
              className="request-order-form__button"
              onClick={submitForm}
              variant="contained"
              startIcon={<SaveIcon />}
              disabled={isOrderCreated}
            >
              Save
            </Button>
            <Button
              className="request-order-form__button"
              onClick={() => onCancelEdit(setValues)}
              variant="outlined"
              disabled={isOrderCreated}
            >
              Cancel
            </Button>
          </span>
        )
      }
      {
        !isEditMode && (
          <Button
            className="request-order-form__button"
            onClick={() => setIsEditMode(true)}
            variant="outlined"
            startIcon={<ModeEditIcon />}
            disabled={isOrderCreated}
          >
            Edit
          </Button>
        )
      }
      {isSendButtonDisabled || isRequestOrdersActionLoading ? (
        <Tooltip
          title="The order has been requested"
          arrow
          placement="top"
        >
          <Button
            onClick={noop}
            variant="contained"
            disabled
            sx={{ '&.Mui-disabled': { pointerEvents: 'all' } }}
          >
            send
          </Button>
        </Tooltip>
      ) : (
        <Button
          onClick={() => setIsConfirmModalOpen(true)}
          variant="contained"
        >
          send
        </Button>
      ) }
    </Grid>
  );
};

export default RequestOrderFormButtons;
