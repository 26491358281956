import useIsUserTeamRoleName from 'core/hooks/useIsUserTeamRoleName';
import useIsTeamRolesDepartmentMember from 'core/hooks/useIsTeamRolesDepartmentMember';
import { UserTeamRoleName, UserTeamRoleTeamDepartment } from 'pages/UserManagement/UserManagement.models';

const { DEPARTMENT_HEAD } = UserTeamRoleName;
const {
  PROJECT_MANAGER, CONTRACT_SPECIALIST, BILLING_SPECIALIST, RETENTION_MANAGER,
} = UserTeamRoleTeamDepartment;

export const useEngagementsFormPermission = () => {
  const isDepartmentHead = useIsUserTeamRoleName(DEPARTMENT_HEAD);

  const isContractSpecialist = useIsTeamRolesDepartmentMember(CONTRACT_SPECIALIST);
  const isBillingSpecialist = useIsTeamRolesDepartmentMember(BILLING_SPECIALIST);
  const isRetentionManager = useIsTeamRolesDepartmentMember(RETENTION_MANAGER);
  const isProjectManager = useIsTeamRolesDepartmentMember(PROJECT_MANAGER);

  const isProjectManagerNotDepartmentHead = isProjectManager && !isDepartmentHead;

  const isUnavailableEngagementActions = isRetentionManager
   || isContractSpecialist
   || isBillingSpecialist
   || isProjectManagerNotDepartmentHead;

  return {
    isUnavailableEngagementActions,
  };
};
