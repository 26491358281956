import { IAttachment } from 'shared/widgets/Attachments/Attachments.model';

export interface IInsideSalesListItem {
  id: number
  companyName: string
  sellerFullname: string
  insideSalesManagerFullname: string
  engagementType: EngagementType
  requestType: RequestType
  sellerNote: string
  managerNote: string
}

export interface IInsideSalesDraftItem {
  companyName: string
  requestType: RequestType
  sellerNote: string
}

export enum EngagementType {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
}

export enum RequestType {
  QUOTE = 'QUOTE',
  QUOTE_AND_PROPOSAL = 'QUOTE_AND_PROPOSAL',
}

export enum FileCategory {
  ATTACHMENT = 'ATTACHMENT',
  PROPOSAL = 'PROPOSAL',
}

export interface IInsideSalesRequestAttachment extends IAttachment {
  insideSalesRequestId: number
}

export interface IInsideSalesRequestCancelRequest {
  cancellationReason: string
}
